import { Alert, Link } from '@ghs/components';
import { Box, CircularProgress, Container } from '@mui/material';
import { useUnit } from 'effector-react';
import React, { Fragment } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import BiDashboardEmbed from '../../components/BiDashboardEmbed';
import { $$bi, UserDashboardStatus } from '../../services/BiService';
import { hasDashboardPermission } from '../../services/PermissionService/PermissionService';
import { $$app } from '../../components/App/model';
import CuratingMessage from './CuratingMessage';
import { $$contactService } from '../../services/ContactService/model';

type BiDashboardProps = {
  unmountShouldDestroyFrame?: boolean;
  unmountShouldUnloadEmbedSdk?: boolean;
  containerElement?: HTMLDivElement;
  containerElementId?: string;
};

export default function BiDashboard({
  unmountShouldDestroyFrame,
  unmountShouldUnloadEmbedSdk,
  containerElement: sisenseContainerElement,
  containerElementId: sisenseContainerElementId
}: BiDashboardProps) {
  const userDetails = useUnit($$app.$userDetails);
  const biThemeId = useUnit($$bi.$biThemeId);
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const sisenseInfo = useUnit($$bi.$sisenseInfo);
  const userDashboardStatus = useUnit($$bi.$userDashboardStatus);
  const reloadSisenseInfoRetryCount = useUnit($$bi.$reloadSisenseInfoRetryCount);
  const contactInfo = useUnit($$contactService.$contactInfo);
  // Load the dashboard specified as a query parameter (In dashboards page), otherwise fall back to the default dashboard (home page)
  const dashboardId = queryParameters.get('dashboardId') || sisenseInfo?.defaultDashboardId;
  const containerElement = sisenseContainerElement || React.createRef();
  const containerElementId = sisenseContainerElementId || 'dashboard-container';

  React.useEffect(() => {
    /**
     * Initializes the component
     *
     * @returns {Promise<void>} Promise with the result of initialization
     */
    async function init() {
      // Update the browser tab title with the name of the dashboard. We could avoid this by adding the
      // dashboard title as a query parameter, but would make the URL to look ugly. The dashboard info is
      // loaded into session so this doesn't require additional calls to Sisense server.
      if (location.pathname !== '/') {
        const dashboard = sisenseInfo?.dashboards?.find(item => item.id === dashboardId);
        if (dashboard?.title) {
          document.title = dashboard.title;
        }
      }
    }
    init();
  }, [sisenseInfo]);

  const userHasAccessToDashboard = () => {
    // When the user is authenticated but doesn't have access to the dashboard
    return sisenseInfo?.dashboards.some(dashboard => dashboard.id === dashboardId);
  };

  if (!hasDashboardPermission(userDetails?.permissions)) {
    return <Container data-testid="bi-dashboard-empty-container" />;
  }

  switch (userDashboardStatus) {
    case UserDashboardStatus.FIRST_AUTHENTICATION_ATTEMPT:
      return (
        <Box id="authenticating-msg" display="flex" justifyContent="center" alignItems="center" mt={1}>
          <CircularProgress />
        </Box>
      );
    case UserDashboardStatus.RETRYING_AUTHENTICATION:
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box sx={{ maxWidth: 'md' }}>
            <Alert id="retrying-authentication-msg" severity="warning" sx={{ mb: 2, ml: 3, mr: 3 }}>
              <Fragment>
                Aw Snap! We hit a glitch. Our system is automatically trying to load your dashboards, and we appreciate your patience as it may take us up to 60 seconds to get over the hump.
                <br />
                <br />
                <CircularProgress size="1.5rem" /> Attempt {reloadSisenseInfoRetryCount}. Trying to reconnect...
              </Fragment>
            </Alert>
          </Box>
        </Box>
      );
    case UserDashboardStatus.MAX_AUTHENTICATION_RETRIES_REACHED:
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box sx={{ maxWidth: 'md' }}>
            <Alert id="failed-authenticated-msg" severity="error" sx={{ mb: 2, ml: 3, mr: 3 }}>
              <Fragment>
                Unfortunately our glitch turned into a brick wall and we are unable to resolve the issue. Please reach out to{' '}
                {contactInfo?.email ? <Link href={`mailto:${contactInfo.email}`}>{contactInfo.email}</Link> : 'your tracking supplier'} and provide your email address that was used, along with the
                date/time of the instance. We apologize for the inconvenience.
              </Fragment>
            </Alert>
          </Box>
        </Box>
      );
    case UserDashboardStatus.USER_NOT_FULLY_SETUP:
      return <CuratingMessage />;
  }

  if (userDashboardStatus === UserDashboardStatus.USER_AUTHENTICATED_WITH_DASHBOARDS && !userHasAccessToDashboard()) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box sx={{ maxWidth: 'md' }}>
          <Alert id="no-access-to-dashboard-msg" severity="warning" sx={{ mb: 2, ml: 3, mr: 3 }}>
            <Fragment>
              Hmmm... that dashboard does not exist but there are others within your{' '}
              <Link component={RouterLink} to={'/dashboards'}>
                dashboards page
              </Link>{' '}
              that are ready and waiting!
            </Fragment>
          </Alert>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <BiDashboardEmbed
        serverUrl={import.meta.env.VITE_BI_SERVER_URL}
        dashboardId={dashboardId}
        showLeftPane={import.meta.env.VITE_BI_SERVER_SHOW_LEFT_PANE === 'true'}
        showRightPane={import.meta.env.VITE_BI_SERVER_SHOW_RIGHT_PANE === 'true'}
        showToolbar={import.meta.env.VITE_BI_SERVER_SHOW_TOOLBAR === 'true'}
        themeId={biThemeId}
        unmountShouldDestroySisenseFrame={unmountShouldDestroyFrame}
        unmountShouldUnloadEmbedSdk={unmountShouldUnloadEmbedSdk}
        sisenseContainerElement={containerElement}
        sisenseContainerElementId={containerElementId}
        timeout={import.meta.env.VITE_SESSION_TIMEOUT * 1000}
        promptBeforeIdle={import.meta.env.VITE_SESSION_TIMEOUT_WARNING * 1000}
        crossTab={true}
      />
    </Box>
  );
}
