import { Button, Card } from '@ghs/components';
import { Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import qs from 'qs';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { hasAddressVerificationPermission } from '../../services/PermissionService/PermissionService';
import { formatCityStateZip } from '../../util/AddressUtils';
import { $$splResultsPage } from './model';

export default function Address() {
  const userDetails = useContext(UserContext);

  const data = useUnit($$splResultsPage.$mailPiece);
  const isLoading = useUnit($$splResultsPage.$isMailDetailsFxPending);

  if (isLoading) {
    return (
      <Card header="Address" sx={{ m: 1 }}>
        <Stack id="address-skeleton" spacing={1}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Stack>
      </Card>
    );
  }

  if (!data) {
    return null;
  }

  const isButtonHidden = !hasAddressVerificationPermission(userDetails.permissions);

  return (
    <Card
      data-testid="address-container"
      header="Address"
      sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: 'fit-content', mt: -1, boxShadow: 'none', borderRadius: '1rem', border: '1px solid', borderColor: 'secondary.dark' }}
    >
      {data.name ? <Typography data-testid="address-name">{data.name}</Typography> : null}
      {data.businessName ? <Typography data-testid="business-name">{data.businessName}</Typography> : null}
      {data.address1 ? <Typography data-testid="address-1">{data.address1}</Typography> : null}
      {data.address2 ? <Typography data-testid="address-2">{data.address2}</Typography> : null}
      {data.address3 ? <Typography data-testid="address-3">{data.address3}</Typography> : null}
      <Typography data-testid="city-state-zip">{formatCityStateZip(data.city, data.state, data.zip5d, data.zipPlus4)}</Typography>
      <Stack direction="row" justifyContent="end" gap={1}>
        <Tooltip title="See the Address Verification Results of your input address for detailed CASS information.">
          <Link to={`/addressVerification?${qs.stringify({ street: data.address, city: data.city, state: data.state, zip: data.zip })}`}>
            <Button data-testid="verification-results-button" variant="outlined" size="medium" color="primary" hidden={isButtonHidden} sx={{ width: 'fit-content' }}>
              Verification Results
            </Button>
          </Link>
        </Tooltip>
      </Stack>
    </Card>
  );
}
