import { Box, Typography } from '@mui/material';
import { DataGrid } from '@ghs/components';
import { gridClasses } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import DateCell from '../DateCell.jsx';

/**
 * VersionsDeleteConfirmationDialog component
 *
 * @param {object} props - props
 * @param {Reseller.Version[]} props.versions - versions
 * @returns {React.ReactNode} - JSX Element
 */
export default function VersionsDeleteConfirmationDialog(props) {
  const { versions } = props;

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Version>} */
  const dataColumnProps = {
    field: '',
    editable: true,
    flex: 1,
    valueGetter: params => dayjs(params).toDate(),
    renderEditCell: ({ row, id, field }) => <DateCell id={id} field={field} row={row} onValueChanged={() => {}} />,
    type: 'date'
  };

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.Version>[]} */
  const columns = [
    { field: 'name', headerName: 'Name', editable: true, flex: 1 },
    { field: 'number', headerName: 'Number', editable: true, flex: 1 },
    { ...dataColumnProps, field: 'firstMailDate', headerName: 'First mail date' },
    { ...dataColumnProps, field: 'lastMailDate', headerName: 'Last mail date' },
    { ...dataColumnProps, field: 'inHomeStartDate', headerName: 'In home start date' },
    { ...dataColumnProps, field: 'inHomeEndDate', headerName: 'In home end date' },
    { field: 'mailClass', headerName: 'Mail class', flex: 1, valueGetter: params => /** @type {Reseller.Version['mailClass']} */ (params).description },
    { field: 'mailQuantity', headerName: 'Mail quantity', flex: 1 }
  ];

  return (
    <>
      <Typography variant="body1">Are you sure you wish to delete the selected versions?</Typography>
      <Box sx={{ height: '50vh' }}>
        <DataGrid
          rows={versions || []}
          columnHeaderHeight={0}
          hideFooter
          columns={columns}
          getRowId={row => row.id}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          initialState={{ columns: { columnVisibilityModel: { id: false } } }}
          sx={{
            border: 'none',
            [`.${gridClasses.columnHeader}`]: {
              display: 'none'
            },
            [`.${gridClasses.cell}`]: {
              userSelect: 'none'
            }
          }}
        />
      </Box>
    </>
  );
}

VersionsDeleteConfirmationDialog.propTypes = {
  versions: PropTypes.array.isRequired
};
