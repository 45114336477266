import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactElement } from 'react';

type DefaultTextFieldInputLabelProps = {
  fieldName: string;
  labelTitle: string;
  fieldDescription: ReactElement;
  focusStates: Record<string, boolean>;
};

export default function DefaultTextFieldInputLabel({ fieldName, labelTitle, fieldDescription, focusStates }: DefaultTextFieldInputLabelProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '2px' }}>{labelTitle}</span>
      {focusStates[fieldName] && (
        <Tooltip title={fieldDescription} sx={{ fontSize: 1 }} arrow>
          <InfoOutlinedIcon sx={{ fontSize: 15, verticalAlign: 'super' }} />
        </Tooltip>
      )}
    </div>
  );
}
