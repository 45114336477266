import { createEffect, createStore, Effect, sample } from 'effector';
import { ghApi } from '../DataService';

const $roles = createStore(/** @type {Roles.Role[]} */ ([]));
const $adminRoles = createStore(/** @type {Roles.Role[]} */ ([]));

const getManageableRolesFx: Effect<void, Roles.Role[]> = createEffect(async () => {
  return ghApi.get('/users/roles').then(response => response.data);
});

const adminGetManageableRolesFx: Effect<void, Roles.Role[]> = createEffect(async () => {
  return ghApi.get('/admin/roles').then(response => response.data);
});

const getUsersForRole: Effect<{ roleId: Roles.Role['id']; size?: number; page?: number }, PaginatedResponse<Users.User>> = createEffect(async ({ roleId, ...params }) => {
  return ghApi.get(`/users/roles/${roleId}/users`, { params }).then(response => response.data);
});

const adminGetUsersForRole: Effect<{ roleId: Roles.Role['id']; size?: number; page?: number }, PaginatedResponse<Users.User>> = createEffect(async ({ roleId, ...params }) => {
  return ghApi.get(`/admin/roles/${roleId}/users`, { params }).then(response => response.data);
});

const assignUserToRoleFx: Effect<{ roleId: Roles.Role['id']; userIds: Users.User['userId'][] }, Users.User[]> = createEffect(async ({ roleId, userIds }) => {
  return ghApi.patch(`/users/roles/${roleId}/users`, userIds).then(response => response.data);
});

const adminAssignUserToRoleFx: Effect<{ roleId: Roles.Role['id']; userIds: Users.User['userId'][] }, Users.User[]> = createEffect(async ({ roleId, userIds }) => {
  return ghApi.patch(`/admin/roles/${roleId}/users`, userIds).then(response => response.data);
});

const deleteUsersFromRoleFx: Effect<{ roleId: Roles.Role['id']; userIds: Users.User['userId'][] }, Users.User[]> = createEffect(async ({ roleId, userIds }) => {
  return ghApi.delete(`/users/roles/${roleId}/users`, { data: userIds }).then(response => response.data);
});

const adminDeleteUsersFromRoleFx: Effect<{ roleId: Roles.Role['id']; userIds: Users.User['userId'][] }, Users.User[]> = createEffect(async ({ roleId, userIds }) => {
  return ghApi.delete(`/admin/roles/${roleId}/users`, { data: userIds }).then(response => response.data);
});

sample({
  clock: getManageableRolesFx.doneData,
  target: $roles
});

sample({
  clock: adminGetManageableRolesFx.doneData,
  target: $adminRoles
});

export const $$roles = {
  $roles,
  $adminRoles,

  getManageableRolesFx,
  adminGetManageableRolesFx,
  getUsersForRole,
  adminGetUsersForRole,
  assignUserToRoleFx,
  adminAssignUserToRoleFx,
  deleteUsersFromRoleFx,
  adminDeleteUsersFromRoleFx,
};
