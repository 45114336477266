import { attach, createEvent, createStore, EventCallable, restore, sample, Store } from 'effector';
import { persist } from 'effector-storage/local';
import { keyBy } from 'lodash';
import { spread } from 'patronum';
import createDataGridModel from '../../../util/createDataGridModel';
import { createDialog } from '../../../util/createDialog';
import { VOID } from '../../../util/JsonUtils';
import { $$confirmationDialog } from '../../ConfirmationDialog/model';
import { $$snackbar } from '../../SnackbarRoot/model.js';
import { createDeleteConfirmationDialogArg } from './utils.ts';
import { ManageUsersAssignedToRoleModal } from './type.ts';

export const createManageUsersAssignedToRoleModal = ({ getUsersForRole, deleteUsersFromRoleFx, assignUserToRoleFx, getUsersFx, $roles }: ManageUsersAssignedToRoleModal) => {
  const _deleteUsersFromRoleFx = attach({ effect: deleteUsersFromRoleFx });
  const _assignUserToRoleFx = attach({ effect: assignUserToRoleFx });

  const dialog: DialogFactory<Partial<{ roleId: Roles.Role['id'] }>> = createDialog();

  const openDeleteConfirmation: EventCallable<Roles.Role['id']> = createEvent();

  const deleteConfirmationSkipChecked: EventCallable<boolean> = createEvent();

  const userSelected: EventCallable<Users.User | null> = createEvent();

  const deleteConfirmed: EventCallable<Users.User['userId']> = createEvent();

  const saveClicked: EventCallable<void> = createEvent();

  const refetch: EventCallable<void> = createEvent();

  const $deleteConfirmationSkipped = createStore(/** @type {boolean} */ false);

  // @ts-ignore
  const $usersToAdd: Store<
    {
      id: Users.User['userId'];
      label: string;
    }[]
  > = restore(userSelected, []).reset(sample({ clock: [dialog.close, _assignUserToRoleFx.done] }));

  const getFreshRowsFx = attach({
    source: dialog.$state.map(state => state?.roleId || ''),
    mapParams: ({ page, size }, roleId): { page: number; size: number; roleId: Roles.Role['id'] } => ({
      page,
      size,
      roleId
    }),
    effect: getUsersForRole
  });

  const $$usersDataGrid = createDataGridModel({
    type: 'server',
    effect: getFreshRowsFx,
    rowIdField: 'userId',
    refetch
  });

  /** @type {import('effector').Store<Roles.Role | null>} */
  const $role = sample({
    source: {
      roles: $roles,
      roleId: dialog.$state.map(params => params?.roleId || null)
    },
    fn: ({ roles, roleId }) => roles.find(role => role.id === roleId) || null
  });

  persist({ store: $deleteConfirmationSkipped, key: 'gh:admin-roles-users:skip-role-delete-confirm' });

  sample({
    clock: deleteConfirmationSkipChecked,
    target: $deleteConfirmationSkipped
  });

  sample({
    source: {
      users: $$usersDataGrid.$data,
      shouldSkip: $deleteConfirmationSkipped
    },
    clock: openDeleteConfirmation,
    fn: ({ shouldSkip, users }, userId) =>
      shouldSkip
        ? { deleteConfirmed: userId }
        : {
            confirm: createDeleteConfirmationDialogArg({
              user: keyBy(users, 'userId')[userId],
              deleteConfirmationSkipChecked,
              deleteConfirmed
            })
          },
    target: spread({ deleteConfirmed, confirm: $$confirmationDialog.open })
  });

  sample({
    source: dialog.$state.map(params => params?.roleId || ''),
    clock: deleteConfirmed,
    fn: (roleId, userId) => ({ roleId, userIds: [userId] }),
    target: _deleteUsersFromRoleFx
  });

  sample({
    source: {
      userIds: $usersToAdd.map(users => users.map(user => user.id)),
      roleId: dialog.$state.map(params => params?.roleId || '')
    },
    clock: saveClicked,
    fn: ({ userIds, roleId }) =>
      userIds.length
        ? {
            saveFx: /** @type  {{ roleId: Roles.Role['id']; userIds: Users.User['userId'][] }} */ {
              roleId,
              userIds
            }
          }
        : { close: VOID },
    target: spread({ saveFx: _assignUserToRoleFx, close: dialog.close })
  });

  sample({
    clock: [_assignUserToRoleFx.done, _deleteUsersFromRoleFx.done],
    target: refetch
  });

  sample({
    clock: [
      _assignUserToRoleFx.done.map(params => ({
        params,
        action: 'assign'
      })),
      _deleteUsersFromRoleFx.done.map(params => ({ params, action: 'delete' }))
    ],
    filter: dialog.$state.map(params => !!params?.roleId),
    fn: ({ action }) => ({
      message: action === 'assign' ? 'User(s) assigned to role' : 'User deleted from role',
      severity: 'success'
    }),
    target: $$snackbar.open
  });

  sample({
    clock: [
      _assignUserToRoleFx.fail.map(response => ({
        response,
        action: 'assign'
      })),
      _deleteUsersFromRoleFx.fail.map(response => ({ response, action: 'delete' }))
    ],
    fn: ({ response, action }) => {
      const { error } = response;
      let message = `An error occurred while ${action === 'assign' ? 'assigning' : 'deleting'} the user ${action === 'assign' ? 'to' : 'from'} the role.`;
      if (error.response?.data?.message) {
        message = error.response.data.message;
      }
      return { message: message, severity: 'error' };
    },
    target: $$snackbar.open
  });

  return {
    ...dialog,

    $$rolesDataGrid: $$usersDataGrid,

    $role,
    $usersToAdd,

    openDeleteConfirmation,
    saveClicked,
    userSelected,

    getUsersFx
  };
};
