import { GridRowModes } from '@mui/x-data-grid-pro';
import { attach, createEffect, createEvent, createStore, restore, sample } from 'effector';
import { persist as persistLocal } from 'effector-storage/local';
import { persist as persistQuery } from 'effector-storage/query';
import { keyBy } from 'lodash';
import { spread } from 'patronum';
import { $$confirmationDialog } from '../../../components/ConfirmationDialog/model';
import { $$snackbar } from '../../../components/SnackbarRoot/model.js';
import { $$reseller } from '../../../services/ResellerService/model';
import createDataGridModel from '../../../util/createDataGridModel';
import { VOID } from '../../../util/JsonUtils';
import {
  createCustomerDeleteConfirmationDialogArg,
  createCustomerUpdateConfirmationDialogArg,
  createLobUpdateConfirmationDialogArg
} from './utils';

/**
 * @typedef {import('@mui/x-data-grid-pro').GridRowId} GridRowId
 * @typedef {import('@mui/x-data-grid-pro').GridValidRowModel} GridValidRowModel
 * @typedef {import('@mui/x-data-grid-pro').GridApiPro} GridApiPro
 * @typedef {{ id: Reseller.Customer['id'], field: keyof Reseller.Customer, error: string }} ValidationError
 */

/** @type {import('effector').EventCallable<Reseller.Customer['id'] | null>} */
const customerSelected = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const customerSaveClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const lobsSaveClicked = createEvent();

/** @type {import('effector').EventCallable<Reseller.Customer['id'] | null>} */
const customerFilterSelected = createEvent();

/** @type {import('effector').EventCallable<Reseller.LineOfBusiness['id'] | null>} */
const lobFilterSelected = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const customerUpdateConfirmationSkipChecked = createEvent();

/** @type {import('effector').EventCallable<boolean>} */
const lobUpdateConfirmationSkipChecked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const customerDeleteClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const customerCancelClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const customerEditClicked = createEvent();

/** @type {import('effector').EventCallable<void>} */
const refetchCustomers = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const lobDeleteClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const lobCancelClicked = createEvent();

/** @type {import('effector').EventCallable<GridRowId>} */
const lobEditClicked = createEvent();

const resetCustomerFilter = createEvent();

const $selectedCustomerId = createStore(/** @type {Reseller.Customer['id'] | null} */ (null));

const $customerUpdateConfirmationSkipped = createStore(/** @type {boolean} */ (false));

const $lobsUpdateConfirmationSkipped = createStore(/** @type {boolean} */ (false));

const $customerFilter = restore(customerFilterSelected, null).reset(resetCustomerFilter);

const $lobFilter = restore(lobFilterSelected, null);

/** @type {DataGridFactory<Reseller.Customer>} */
const $$customers = createDataGridModel({
  type: 'client',
  effect: $$reseller.getCustomersFx,
  refetch: refetchCustomers
});

const getLineOfBusinessFx = attach({
  source: $selectedCustomerId.map(d => (d ? { customerIds: [d] } : null)),
  mapParams: (_params, state) => state,
  effect: createEffect(async params => {
    if (params && typeof params === 'object') {
      return $$reseller.getLineOfBusinessFx(params);
    } else {
      return /** @type {Reseller.LineOfBusiness[]} */ ([]);
    }
  })
});

/** @type {DataGridFactory<Reseller.LineOfBusiness>} */
const $$lobs = createDataGridModel({
  type: 'client',
  effect: getLineOfBusinessFx,
  refetch: [$selectedCustomerId.updates, $$reseller.deleteLineOfBusinessFx.done]
});

persistLocal({ store: $customerUpdateConfirmationSkipped, key: 'gh:admin-customer:skip-update-customer-confirm' });

persistLocal({ store: $lobsUpdateConfirmationSkipped, key: 'gh:admin-customer:skip-update-lob-confirm' });

persistQuery({ store: $selectedCustomerId, key: 'selected-customer-id' });

sample({
  clock: customerUpdateConfirmationSkipChecked,
  target: $customerUpdateConfirmationSkipped
});

sample({
  clock: lobUpdateConfirmationSkipChecked,
  target: $lobsUpdateConfirmationSkipped
});

sample({
  clock: [customerSelected, customerFilterSelected],
  target: $selectedCustomerId
});

// Update the selection model in the DataGrid so that the row is highlighted when selecting an item in the dropdown
sample({
  clock: customerFilterSelected,
  fn: customerId => (customerId ? { selectRow: customerId } : { unselectAllRows: VOID }),
  target: spread({ selectRow: $$customers.selectRow, unselectRow: $$customers.unselectAllRows })
});

sample({
  clock: $$reseller.deleteCustomerFx.done,
  target: refetchCustomers
});

sample({
  clock: [$$customers.tableMounted.filter({ fn: isMounted => !isMounted }), $$reseller.deleteCustomerFx.done],
  target: $selectedCustomerId.reinit
});

sample({
  source: {
    rows: $$customers.$updatedRows,
    dialogShouldNotShow: $customerUpdateConfirmationSkipped
  },
  clock: customerSaveClicked,
  fn: ({ rows, dialogShouldNotShow }, id) =>
    dialogShouldNotShow
      ? { updateCustomerFx: keyBy(rows, 'id')[id] }
      : {
          confirmationDialog: createCustomerUpdateConfirmationDialogArg({
            customerUpdateConfirmationSkipChecked,
            onClose: () => $$customers.resetCellValue({ id, field: 'name' }),
            onCancel: () => $$customers.resetCellValue({ id, field: 'name' }),
            onAccept: () => $$reseller.updateCustomerFx(keyBy(rows, 'id')[id])
          })
        },
  target: spread({
    updateCustomerFx: $$reseller.updateCustomerFx,
    confirmationDialog: $$confirmationDialog.open
  })
});

sample({
  clock: $$reseller.updateCustomerFx.done,
  fn: ({ params }) => ({
    customerCancelClicked: params.id,
    getCustomersFx: VOID
  }),
  target: spread({
    customerCancelClicked: customerCancelClicked,
    refetchCustomers: refetchCustomers
  })
});

sample({
  source: {
    rows: $$lobs.$updatedRows,
    dialogShouldNotShow: $lobsUpdateConfirmationSkipped
  },
  clock: lobsSaveClicked,
  fn: ({ rows, dialogShouldNotShow }, id) =>
    dialogShouldNotShow
      ? { updateLineOfBusinessFx: keyBy(rows, 'id')[id] }
      : {
          confirmationDialog: createLobUpdateConfirmationDialogArg({
            lobUpdateConfirmationSkipChecked,
            onClose: () => $$lobs.resetCellValue({ id, field: 'name' }),
            onCancel: () => $$lobs.resetCellValue({ id, field: 'name' }),
            onAccept: () => $$reseller.updateLineOfBusinessFx(keyBy(rows, 'id')[id])
          })
        },
  target: spread({
    updateLineOfBusinessFx: $$reseller.updateLineOfBusinessFx,
    confirmationDialog: $$confirmationDialog.open
  })
});

sample({
  clock: $selectedCustomerId.updates,
  fn: () => ({
    lobFilterSelected: null,
    reinitAll: VOID
  }),
  target: spread({
    lobFilterSelected: lobFilterSelected,
    reinitAll: $$lobs.__.reinitAll
  })
});

sample({
  clock: [
    $$reseller.updateCustomerFx.fail.map(response => ({
      response,
      type: 'customer'
    })),
    $$reseller.updateLineOfBusinessFx.fail.map(response => ({ response, type: 'lob' }))
  ],
  fn: ({ response, type }) => {
    const { params, error } = response;
    let message = `An error occurred while updating the ${type === 'customer' ? 'Customer' : 'Line of Business'}.`;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    }
    return {
      snackbar: { message: message, severity: /** @type {const} */ ('error') },
      [type === 'customer' ? 'resetCustomerCellValue' : 'resetLobCellValue']: { id: params.id, field: 'name' }
    };
  },
  target: spread({
    snackbar: $$snackbar.open,
    resetCustomerCellValue: $$customers.resetCellValue,
    resetLobCellValue: $$lobs.resetCellValue
  })
});

sample({
  clock: [
    $$reseller.deleteCustomerFx.fail.map(response => ({
      response,
      type: 'customer'
    })),
    $$reseller.deleteLineOfBusinessFx.fail.map(response => ({ response, type: 'lob' }))
  ],
  fn: ({ response, type }) => {
    const { error } = response;
    let message = `An error occurred while deleting the ${type === 'customer' ? 'Customer' : 'Line of Business'}.`;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    }
    return { message: message, severity: /** @type {const} */ ('error') };
  },
  target: $$snackbar.open
});

sample({
  clock: [
    customerDeleteClicked.map(id => ({ id, type: 'customer' })),
    lobDeleteClicked.map(id => ({
      id,
      type: 'lob'
    }))
  ],
  fn: ({ id, type }) =>
    ({
      customer: createCustomerDeleteConfirmationDialogArg({ type, onAccept: () => $$reseller.deleteCustomerFx(id) }),
      lob: createCustomerDeleteConfirmationDialogArg({ type, onAccept: () => $$reseller.deleteLineOfBusinessFx(id) })
    })[type],
  target: $$confirmationDialog.open
});

sample({
  source: $$customers.$data,
  clock: $$reseller.updateCustomerFx.done,
  fn: (rows, { params }) => ({
    snackbar: { message: 'Customer updated.', severity: /** @type {const} */ ('success') },
    highlightRows: [params.id],
    $data: rows.map(row => (row.id === params.id ? params : row))
  }),
  target: spread({
    snackbar: $$snackbar.open,
    highlightRows: $$customers.highlightRows,
    $data: $$customers.$data
  })
});

sample({
  source: $$lobs.$data,
  clock: $$reseller.updateLineOfBusinessFx.done,
  fn: (rows, { params }) => ({
    snackbar: { message: 'Line of Business updated.', severity: /** @type {const} */ ('success') },
    highlightRows: [params.id],
    $data: rows.map(row => (row.id === params.id ? params : row))
  }),
  target: spread({
    snackbar: $$snackbar.open,
    highlightRows: $$lobs.highlightRows,
    $data: $$lobs.$data
  })
});

sample({
  clock: [$$reseller.deleteCustomerFx.done.map(() => ({ type: 'customer' })), $$reseller.deleteLineOfBusinessFx.done.map(() => ({ type: 'lob' }))],
  fn: ({ type }) => ({
    message: `${type === 'customer' ? 'Customer' : 'Line of Business'} deleted.`,
    severity: /** @type {const} */ ('success')
  }),
  target: $$snackbar.open
});

sample({
  clock: lobsSaveClicked,
  fn: id => ({ id, mode: GridRowModes.View }),
  target: $$lobs.setRowMode
});

sample({
  clock: lobCancelClicked,
  fn: id => ({
    resetRowChanges: id,
    setRowMode: { id, mode: GridRowModes.View }
  }),
  target: spread({
    resetRowChanges: $$lobs.resetRowChanges,
    setRowMode: $$lobs.setRowMode
  })
});

sample({
  clock: customerCancelClicked,
  fn: id => ({
    resetRowChanges: id,
    setRowMode: { id, mode: GridRowModes.View }
  }),
  target: spread({
    resetRowChanges: $$customers.resetRowChanges,
    setRowMode: $$customers.setRowMode
  })
});

sample({
  clock: lobEditClicked,
  fn: id => ({ id, mode: GridRowModes.Edit }),
  target: $$lobs.setRowMode
});

sample({
  clock: customerEditClicked,
  fn: id => ({ id, mode: GridRowModes.Edit }),
  target: $$customers.setRowMode
});

sample({
  source: {
    rows: $$customers.$rows,
    updatedRows: $$customers.$updatedRows
  },
  fn: ({ rows, updatedRows }) => {
    const duplicateNameErrors = updatedRows
      .filter(row => rows.some(r => r.id !== row.id && r.name.trim().toLowerCase() === row.name.trim().toLowerCase()))
      .map(({ id }) => ({
        id,
        field: 'name',
        error: `The same customer name already exists and can’t be duplicated.`
      }));
    const emptyNameErrors = updatedRows
      .filter(row => row.name === '')
      .map(({ id }) => ({
        id,
        field: 'name',
        error: `Customer name is required.`
      }));
    return [...duplicateNameErrors, ...emptyNameErrors];
  },
  target: $$customers.setErrors
});

sample({
  source: {
    rows: $$lobs.$rows,
    updatedRows: $$lobs.$updatedRows
  },
  fn: ({ rows, updatedRows }) => {
    const duplicateNameErrors = updatedRows
      .filter(row => rows.some(r => r.id !== row.id && r.name.trim().toLowerCase() === row.name.trim().toLowerCase()))
      .map(({ id }) => ({
        id,
        field: 'name',
        error: `The same line of business name already exists and can’t be duplicated.`
      }));
    const emptyNameErrors = updatedRows
      .filter(row => row.name === '')
      .map(({ id }) => ({
        id,
        field: 'name',
        error: `Line of Business name is required.`
      }));
    return [...duplicateNameErrors, ...emptyNameErrors];
  },
  target: $$lobs.setErrors
});

export const $$adminCustomersPage = {
  customers: {
    $$table: $$customers,
    $filter: $customerFilter.map(d => d),

    saveClicked: customerSaveClicked,
    filterSelected: customerFilterSelected,
    deleteClicked: customerDeleteClicked,
    cancelClicked: customerCancelClicked,
    editClicked: customerEditClicked
  },

  lobs: {
    $$table: $$lobs,
    $filter: $lobFilter.map(d => d),

    saveClicked: lobsSaveClicked,
    filterSelected: lobFilterSelected,
    deleteClicked: lobDeleteClicked,
    cancelClicked: lobCancelClicked,
    editClicked: lobEditClicked
  },

  $selectedCustomerId,

  customerSelected
};
