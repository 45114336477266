import { createStore, createEffect, createEvent, sample } from 'effector';
import { transformDashboardsThumbnailsUrls } from '../../util/ThumbnailsUtils';
import { ghApi, sisenseApi } from '../DataService';
import { addServerUrlToImage } from '../LogoService';
import { $$theme } from '../ThemeService/model';
import { DashboardView, SisenseAuthenticationStatus, SisenseInfo, UserDashboardStatus, UserDashboardStatusType } from './types';
import { delay, spread } from 'patronum';
import {
  BiAuthenticationError,
  createSisenseInfo,
  RELOAD_SISENSE_INFO_MAX_RETRIES,
  SISENSE_INFO_AUTHENTICATING,
  SISENSE_INFO_AUTHENTICATION_FAILED,
  SISENSE_INFO_NOT_AUTHENTICATED,
  SISENSE_THEMES_MAP
} from './BiUtils';

//-------------------------------- Effects -------------------------------
const getSisenseInfoFx = createEffect<number, SisenseInfo>(async reloadCount => {
  return getSisenseInfo(reloadCount);
});

const performBiServerLogoutFx = createEffect(async () => {
  // Logout to invalidate all current user's sessions
  sisenseApi
    .get('/authentication/logout_all')
    .then(() => true)
    .catch(error => {
      console.error(`Could not perform BI server logout. ${error.response?.status} - ${error.response?.statusText}`);
      // Show full error to identify unexpected errors. This will be deleted after some time running with no issues
      console.error(`Full BI server error: ${JSON.stringify(error)}`);
      throw error;
    });
});

//-------------------------------- Events --------------------------------
const reinitAll = createEvent<void>();
const reloadSisenseInfo = createEvent<void>();

//-------------------------------- Stores --------------------------------
const $sisenseInfo = createStore<SisenseInfo>(SISENSE_INFO_NOT_AUTHENTICATED).reset(performBiServerLogoutFx, reinitAll);
const $biThemeId = $$theme.$theme.map(theme => SISENSE_THEMES_MAP[theme] || null);

const $reloadSisenseInfoRetryCount = createStore(1).reset(getSisenseInfoFx.done, performBiServerLogoutFx, reinitAll);
const reloadSisenseInfoDelay = delay({
  source: sample({
    source: $reloadSisenseInfoRetryCount,
    // Ignore the reset value so the retries doesn't start again once $reloadSisenseInfoRetryCount is reset with getSisenseInfoFx.done
    filter: reloadSisenseInfoRetryCount => reloadSisenseInfoRetryCount !== 1
  }),
  timeout: reloadSisenseInfoRetryCount => {
    if (reloadSisenseInfoRetryCount === 2) {
      return 10000;
    } else if (reloadSisenseInfoRetryCount === 3) {
      return 20000;
    } else {
      return 30000;
    }
  }
});

const $userDashboardStatus = sample({
  source: {
    sisenseInfo: $sisenseInfo,
    reloadSisenseInfoRetryCount: $reloadSisenseInfoRetryCount
  },
  fn: ({ sisenseInfo, reloadSisenseInfoRetryCount }): UserDashboardStatusType => {
    if (
      (sisenseInfo?.authenticationStatus === SisenseAuthenticationStatus.NOT_AUTHENTICATED || sisenseInfo?.authenticationStatus === SisenseAuthenticationStatus.AUTHENTICATING) &&
      reloadSisenseInfoRetryCount === 1
    ) {
      return UserDashboardStatus.FIRST_AUTHENTICATION_ATTEMPT;
    } else if (sisenseInfo?.authenticationStatus === SisenseAuthenticationStatus.AUTHENTICATING && reloadSisenseInfoRetryCount <= RELOAD_SISENSE_INFO_MAX_RETRIES + 1) {
      return UserDashboardStatus.RETRYING_AUTHENTICATION;
    } else if (sisenseInfo?.authenticationStatus === SisenseAuthenticationStatus.AUTHENTICATION_FAILED) {
      return UserDashboardStatus.MAX_AUTHENTICATION_RETRIES_REACHED;
    } else if (sisenseInfo?.authenticationStatus === SisenseAuthenticationStatus.AUTHENTICATED && sisenseInfo?.dashboardsNo == 0) {
      // When the user is created in Sisense but is not fully setup (Assigning the user to groups in legacy databases
      // may take some time), the user will be authenticated but won't have access to any dashboard.
      return UserDashboardStatus.USER_NOT_FULLY_SETUP;
    } else if (sisenseInfo?.authenticationStatus === SisenseAuthenticationStatus.AUTHENTICATED) {
      return UserDashboardStatus.USER_AUTHENTICATED_WITH_DASHBOARDS;
    }
    return undefined;
  }
});

//-------------------------------- Samples -------------------------------
sample({
  source: $sisenseInfo,
  clock: [reloadSisenseInfo, reloadSisenseInfoDelay],
  // Increase reload count to force the refresh of the memoized dashboard in the home page
  fn: (sisenseInfo, _) => (sisenseInfo?.reloadCount || 0) + 1,
  target: getSisenseInfoFx
});

sample({
  clock: getSisenseInfoFx.doneData,
  target: $sisenseInfo
});

sample({
  source: $reloadSisenseInfoRetryCount,
  clock: getSisenseInfoFx.fail,
  fn: (reloadSisenseInfoRetryCount, error) => {
    console.error('Failed to load Sisense info:', error); // Delete or keep it for troubleshooting

    const result: {
      sisenseInfo?: SisenseInfo;
      reloadSisenseInfoRetryCount?: number;
    } = {};

    if (reloadSisenseInfoRetryCount <= RELOAD_SISENSE_INFO_MAX_RETRIES) {
      // If retry count is within limits, increase the retry counter so the delayed effect is called
      result.reloadSisenseInfoRetryCount = reloadSisenseInfoRetryCount + 1;
      result.sisenseInfo = SISENSE_INFO_AUTHENTICATING;
    } else {
      // Otherwise, set $sisenseInfo to its final failed authentication state
      result.sisenseInfo = SISENSE_INFO_AUTHENTICATION_FAILED;
    }

    return result;
  },
  target: spread({
    sisenseInfo: $sisenseInfo,
    reloadSisenseInfoRetryCount: $reloadSisenseInfoRetryCount
  })
});

//-------------------------------- Private functions -------------------------------

/**
 * Returns the BI server SSO login URL with a valid JWT token.
 *
 * @returns {Promise<string>} The BI server SSO login URL.
 */
const getBiLoginRedirectUrl = async (): Promise<string> => {
  return ghApi
    .get('/api/bi/jwtToken')
    .then(response => response.data)
    .then(data => data.jwtToken)
    .then(biServerToken => `${import.meta.env.VITE_BI_SERVER_URL}/jwt?jwt=${biServerToken}`);
};

/**
 * Performs authentication against BI server.
 */
const performBiServerLogin = async () => {
  try {
    const biLoginUrl = await getBiLoginRedirectUrl();
    const fetchOptions = {
      method: 'GET',
      mode: 'cors',
      redirect: 'follow',
      credentials: 'include'
    } as RequestInit;

    await fetch(biLoginUrl, fetchOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`BI server network response was not ok. ${response.status} - ${response.statusText}`);
        }
        console.log('Authentication against BI server succeeded');
      })
      .catch(error => {
        console.error(`Error authenticating against BI server. ${error}`);
        throw error;
      });

    /* Tried to use axios but failed with. Axios doesn't have a way to specify mode: 'cors' and redirect: 'follow'
    await ghApi
      .get(biLoginUrl, {
        withCredentials: true,
        headers: {
          ...ghApi.defaults.headers,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS'
        },
        maxRedirects: 5
      })
      .then(() => {
        console.log('Authentication against BI server succeeded');
      })
      .catch(error => {
        console.error(`Error authenticating against BI server. ${error}`);
        throw error;
      });
    */
  } catch (authError) {
    console.error('Failed to ensure login against BI server: ' + authError);
  }
};

/**
 * Loads the user status against Sisense server.
 *
 * @param {number} sisenseReloadCount Reload count
 * @returns {Promise<SisenseInfo>} Sisense user information.
 */
async function getSisenseInfo(sisenseReloadCount: number = 0): Promise<SisenseInfo> {
  console.log(`Logging in to the BI server (Retry count: ${$reloadSisenseInfoRetryCount.getState()}. Reload count: ${sisenseReloadCount})... `);

  await performBiServerLogin();

  const dashboards = await getUserDashboards();

  return createSisenseInfo(SisenseAuthenticationStatus.AUTHENTICATED, dashboards, sisenseReloadCount);
}

/**
 * Fetches, from Sisense, all dashboards for current user.
 *
 * @returns {Promise<Pick<DashboardView, 'id' | 'title'>[]>} Promise containing all the dashboards for current user.
 */
async function getSisenseDashboards(): Promise<Pick<DashboardView, 'id' | 'title'>[]> {
  // Configure fetch options to allow redirection to the page that triggered the authentication (/dashboards or /dashboard) once authentication is done
  return (
    sisenseApi
      .get('/dashboards', { params: { fields: 'title' } })
      .catch(response => {
        if (response.status === 401) {
          throw new BiAuthenticationError();
        }
        throw new Error(`Could not load dashboards. ${response.status} - ${response.statusText}`);
      })
      .then(response => response.data)
      // Dashboards that start with `_` should be hidden, so we exclude them
      .then(data => data.filter(dashboard => !dashboard.title?.startsWith('_')))
      .then(data => data.map(dashboard => ({ id: dashboard.oid, title: dashboard.title })))
  );
}

/**
 * Fetches all dashboards for current user.
 *
 * @returns {Promise<DashboardView[]>} Promise containing all the dashboards for current user.
 */
const getUserDashboards = async (): Promise<DashboardView[]> => {
  const sisenseDashboards = await getSisenseDashboards();
  const ids = sisenseDashboards.map(dashboard => dashboard.id).join(',');
  return ghApi
    .get('/api/bi/dashboards', { params: { ids } })
    .then(response => response.data)
    .then(dashboards =>
      dashboards.map(dashboard => ({
        ...dashboard,
        thumbnailLight: addServerUrlToImage(dashboard.thumbnailLight),
        thumbnailDark: addServerUrlToImage(dashboard.thumbnailDark)
      }))
    );
};

/**
 * Fetches all dashboards for admin user.
 *
 * @returns {Promise<DashboardView[]>} Promise containing all the dashboards for admin user.
 */
export async function getAdminDashboards(): Promise<DashboardView[]> {
  return ghApi
    .get('/api/bi/admin/dashboards')
    .then(response => response.data)
    .then(transformDashboardsThumbnailsUrls);
}

/**
 * Updates the metadata (thumbnail image, dashboard description) for a specific dashboard.
 *
 * @param {object} params parameters
 * @param {string} params.id - The ID of the dashboard to update.
 * @param {string} params.description - Description of the dashboard.
 * @param {string} params.thumbnailLightBase64 - Base64 string with the thumbnail for the light theme.
 * @param {string} params.thumbnailDarkBase64 - Base64 string with the thumbnail for the dark theme.
 * @returns {Promise<void>} A promise that resolves when the metadata has been updated.
 */
export const updateAdminDashboardMetadata = async ({ id, ...data }: { id: string; description: string; thumbnailLightBase64: File; thumbnailDarkBase64: File }): Promise<void> => {
  return ghApi.post(`/api/bi/admin/dashboards/${id}`, data).then(response => response.data);
};

export const $$bi = {
  $sisenseInfo,
  $reloadSisenseInfoRetryCount,
  $userDashboardStatus,
  $biThemeId,

  reloadSisenseInfo,
  performBiServerLogoutFx,
  getSisenseInfoFx,

  __: {
    reinitAll
  }
};
