import { createAdminUsersPageModel } from '../../../components/AdminUsersPage/createAdminUsersPageModel.ts';
import ManageRolesTab from '../../../components/AdminUsersPage/ManageRolesTab/ManageRolesTab.tsx';
import ManageUsersTab from '../../../components/AdminUsersPage/ManageUsersTab/ManageUsersTab.tsx';
import MyRolesTab from '../../../components/AdminUsersPage/MyRolesTab/MyRolesTab.tsx';
import { createElement } from 'react';
import { $$ghsAdminManageUsersTab } from './GhsAdminManageUsersTab/model.ts';
import { $$ghsAdminManageRolesTable } from './GhsAdminManageRolesTable/model.ts';
import { $$ghsAdminManageUsersRolesModal } from './GhsAdminManageUserRolesModal/model.ts';
import { $$ghsAdminManageUsersAssignedToRoleModal } from './GhsAdminManageUsersAssignedToRoleModal/model.ts';
import { $$ghsAdminMyRolesTable } from './GhsAdminMyRolesTable/model.ts';

export const $$ghsAdminUsersPage = createAdminUsersPageModel({
  tabs: [
    {
      name: 'manage-users',
      title: 'Manage Users',
      component: createElement(ManageUsersTab, { $$manageUsersTab: $$ghsAdminManageUsersTab, $$manageUserRolesModal: $$ghsAdminManageUsersRolesModal, isExternal: false })
    },
    {
      name: 'manage-roles',
      title: 'Manage Roles',
      component: createElement(ManageRolesTab, { $$manageRolesTable: $$ghsAdminManageRolesTable, $$manageUsersAssignedToRoleModal: $$ghsAdminManageUsersAssignedToRoleModal })
    },
    { name: 'my-roles', title: 'My Roles', component: createElement(MyRolesTab, { $$myRolesTable: $$ghsAdminMyRolesTable }) }
  ],
  openedTabKey: 'ghs-admin-user-selected-tab',
  $$manageUserRolesModal: $$ghsAdminManageUsersRolesModal,
  $$manageUserAssignedToRoleModal: $$ghsAdminManageUsersAssignedToRoleModal
});