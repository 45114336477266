import { DeleteForever } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@ghs/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';
import { isEmpty } from 'lodash';

/**
 * UserRolesTable component
 *
 * @returns {React.ReactNode} UserRolesTable
 */
export default function UserRolesTable({ $$manageUsersAssignedToRoleModal }) {
  const dataGridProps = $$manageUsersAssignedToRoleModal.$$rolesDataGrid.useDataGrid();

  const customerTableColumns: GridColDef<Users.User, never, never>[] = [
    { field: 'name', headerName: 'Users', minWidth: 160, flex: 4 },
    { field: 'email', headerName: 'Email', minWidth: 160, flex: 6 },
    {
      field: 'actions',
      type: 'actions',
      editable: false,
      headerName: 'Actions',
      headerAlign: 'center',
      getActions: ({ id }) => [
        <IconButton key="delete" onClick={() => $$manageUsersAssignedToRoleModal.openDeleteConfirmation(id + '')}>
          <DeleteForever fontSize="small" color="warning" />
        </IconButton>
      ]
    }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: isEmpty(dataGridProps.rows) ? 250 : undefined }}>
      <DataGrid
        {...dataGridProps}
        columns={customerTableColumns}
        disableRowSelectionOnClick
        editMode="row"
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pagination={true}
        pageSizeOptions={[5, 10, 25, 50]}
        autosizeOnMount={false} // vitest is failing beause of this prop
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: true
        }}
        sx={DataGridStylesWithoutSelectionOverride}
      />
    </Box>
  );
}
