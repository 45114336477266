import { createManageUserRolesModal } from '../../../../components/AdminUsersPage/ManageUserRolesModal/createManageUserRolesModal.ts';
import { $$user } from '../../../../services/UserService/UserService';
import { $$roles } from '../../../../services/RolesService/model.ts';

export const $$ghsAdminManageUsersRolesModal = createManageUserRolesModal({
  getUserRolesFx: $$user.adminGetUserRolesFx,
  assignRolesToUserFx: $$user.adminAssignRolesToUserFx,
  getUserByIdFx: $$user.adminGetUserByIdFx,
  getManageableRolesFx: $$roles.adminGetManageableRolesFx,
  deleteRoleFromUserFx: $$user.adminDeleteRoleFromUserFx,
  getUsersFx: $$user.adminGetUsersFx,
});