import { Container, FormControlLabel } from '@mui/material';
import { Button, Switch } from '@ghs/components';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';

type CustomToolbarProps = {
  onlyShowActiveBanners: boolean;
  setOnlyShowActiveBanners: (value: boolean) => void;
  setFilterModel: (filterModel: { items: { id: number; field: string; operator: string; value: string }[] }) => void;
  onClickBannerDialog: () => void;
};

export default function BannersCustomToolbar({ onlyShowActiveBanners, setOnlyShowActiveBanners, setFilterModel, onClickBannerDialog }: CustomToolbarProps) {
  return (
    <GridToolbarContainer>
      <Container>
        <Button color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={onClickBannerDialog}>
          Create
        </Button>
        <FormControlLabel
          sx={{ minWidth: 100, 'margin-left': 30 }}
          checked={onlyShowActiveBanners}
          onChange={event => {
            setOnlyShowActiveBanners(() => event.target.checked);
            if (event.target.checked) {
              setFilterModel(model => ({
                ...model,
                items: [{ id: 1, field: 'active', operator: 'is', value: 'true' }]
              }));
            } else {
              setFilterModel({ items: [] });
            }
          }}
          control={<Switch color="primary" size="small" />}
          label="Only Show Active Banners"
          color="primary"
        />
      </Container>
    </GridToolbarContainer>
  );
}
