import { createEffect, Effect } from 'effector';
import { ghApi } from '../DataService';

const getUsersFx: Effect<Partial<{ search: string; customerId: Reseller.Customer['id'] } & DataGrid.ServerSideParams>, PaginatedResponse<Users.User>> = createEffect(
  async params => {
    return ghApi.get('/users', { params }).then(response => response.data);
  }
);

const adminGetUsersFx: Effect<Partial<{ search: string; customerId: Reseller.Customer['id'] } & DataGrid.ServerSideParams>, PaginatedResponse<Users.User>> = createEffect(
  async params => {
    return ghApi.get('/admin/users', { params }).then(response => response.data);
  }
);

const getUserByIdFx: Effect<Partial<Users.User['userId']>, Users.User> = createEffect(async userId => {
  return ghApi.get(`/users/${userId}`).then(response => response.data);
});

const adminGetUserByIdFx: Effect<Partial<Users.User['userId']>, Users.User> = createEffect(async userId => {
  return ghApi.get(`/admin/users/${userId}`).then(response => response.data);
});

const exportUsersFx: Effect<string[], unknown[]> = createEffect(async data => {
  return ghApi.post('/users/export', data).then(response => response.data);
});

const adminExportUsersFx: Effect<string[], unknown[]> = createEffect(async data => {
  return ghApi.post('/admin/users/export', data).then(response => response.data);
});

const getUserRolesFx: Effect<string, Roles.Role[]> = createEffect(async userId => {
  return ghApi.get(`/users/${userId}/roles`).then(response => response.data);
});

const adminGetUserRolesFx: Effect<string, Roles.Role[]> = createEffect(async userId => {
  return ghApi.get(`/admin/users/${userId}/roles`).then(response => response.data);
});

const assignRoleToUserFx: Effect<{ userId: string; roleId: string }, unknown[]> = createEffect(async ({ userId, roleId }) => {
  return ghApi.post(`/users/${userId}/roles/${roleId}`).then(response => response.data);
});

const adminAssignRoleToUserFx: Effect<{ userId: string; roleId: string }, unknown[]> = createEffect(async ({ userId, roleId }) => {
  return ghApi.post(`/admin/users/${userId}/roles/${roleId}`).then(response => response.data);
});

const assignRolesToUserFx: Effect<{ userId: string; roles: string[] }, unknown[]> = createEffect(async ({ userId, roles }) => {
  return ghApi.patch(`/users/${userId}/roles`, roles).then(response => response.data);
});

const adminAssignRolesToUserFx: Effect<{ userId: string; roles: string[] }, unknown[]> = createEffect(async ({ userId, roles }) => {
  return ghApi.patch(`/admin/users/${userId}/roles`, roles).then(response => response.data);
});

const deleteRoleFromUserFx: Effect<{ userId: string; roleId: string }, unknown[]> = createEffect(async ({ userId, roleId }) => {
  return ghApi.delete(`/users/${userId}/roles/${roleId}`).then(response => response.data);
});

const adminDeleteRoleFromUserFx: Effect<{ userId: string; roleId: string }, unknown[]> = createEffect(async ({ userId, roleId }) => {
  return ghApi.delete(`/admin/users/${userId}/roles/${roleId}`).then(response => response.data);
});

const deleteRolesFromUserFx: Effect<{ userId: string; roles: string[] }, unknown[]> = createEffect(async ({ userId, roles }) => {
  return ghApi.delete(`/users/${userId}/roles`, roles).then(response => response.data);
});

const adminDeleteRolesFromUserFx: Effect<{ userId: string; roles: string[] }, unknown[]> = createEffect(async ({ userId, roles }) => {
  return ghApi.delete(`/admin/users/${userId}/roles`, roles).then(response => response.data);
});

export const $$user = {
  getUsersFx,
  adminGetUsersFx,
  getUserByIdFx,
  adminGetUserByIdFx,
  exportUsersFx,
  adminExportUsersFx,
  getUserRolesFx,
  adminGetUserRolesFx,
  assignRoleToUserFx,
  adminAssignRoleToUserFx,
  assignRolesToUserFx,
  adminAssignRolesToUserFx,
  deleteRoleFromUserFx,
  adminDeleteRoleFromUserFx,
  deleteRolesFromUserFx,
  adminDeleteRolesFromUserFx
};
