import * as React from 'react';
import { Collapse, Container, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Link, MenuItem, Select, TextField } from '@ghs/components';
import { useState } from 'react';
import { $$contactService, sendEmail } from '../services/ContactService/model';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useAuth0 } from '@auth0/auth0-react';
import { useUnit } from 'effector-react';
import { generateErrorAlertWithContact } from '../util/ErrorUtils';

const questions = ['Data Question', 'Dashboard Question/Issue', 'Issue with the Site', 'Suggestion', 'General Comment'];

const successAlertData = {
  severity: 'success',
  title: 'Email sent',
  message: 'Thank you for your feedback. If you had a specific question, a member of our team will contact you soon.'
};

const Contact = () => {
  const { user } = useAuth0();
  const contactInfo = useUnit($$contactService.$contactInfo);

  const defaultFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subject: 'General Comment',
    comments: '',
    errors: {}
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [alertData, setAlertData] = useState(null);
  const [isSendingEmail, setIsSendingEmail] = React.useState(false);

  const handleFormChange = event => {
    const newData = { [event.target.name]: event.target.value };
    setFormData({
      ...formData,
      ...newData
    });
  };

  const isFormValid = () => {
    const errors = {};
    if (!formData.firstName) {
      errors.firstName = 'First Name is required';
    }
    if (!formData.lastName) {
      errors.lastName = 'Last Name is required';
    }
    if (!formData.email) {
      errors.email = 'Email is required';
    }
    if (!validateEmail(formData.email)) {
      errors.email = 'Invalid email format';
    }
    if (!formData.comments) {
      errors.comments = 'Comments is required';
    }
    if (!matchIsValidTel(formData.phone)) {
      errors.phone = 'Phone Number is not valid';
    }

    setFormData({ ...formData, ...{ errors: errors } });
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (isFormValid()) {
      setIsSendingEmail(true);
      try {
        await sendEmail(formData);
        handleClear();
        setAlertData(successAlertData);
      } catch (err) {
        console.error('Error sending email: ', err);
        setAlertData(
          generateErrorAlertWithContact({
            contactInfo: contactInfo,
            userEmail: user?.email,
            requestId: err?.response?.headers?.get('Request-Id'),
            errorMessage: 'There was an error sending your email. Please retry'
          })
        );
      }

      setIsSendingEmail(false);
    }
  };

  const handleClear = () => {
    setFormData(defaultFormData);
  };

  return (
    <Container id="contact-form" component="form">
      {contactInfo?.showContactForm && (
        <>
          <Collapse id="alert-collapse" in={!!alertData && (alertData.title || alertData.message)}>
            <Alert
              severity={alertData?.severity}
              action={
                <IconButton onClick={() => setAlertData({ ...alertData, ...{ title: '', message: '' } })}>
                  <CloseIcon />
                </IconButton>
              }
            >
              <Typography variant="h6">{alertData?.title}</Typography>
              <Typography>{alertData?.message}</Typography>
            </Alert>
          </Collapse>
          <Typography color="text.primary" sx={{ mb: 1 }}>
            We&apos;d love to hear your feedback or questions about the site. Please take a moment to send us your thoughts below.
          </Typography>
          <Stack id="contact-stack">
            <TextField
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleFormChange}
              error={!!formData.errors.firstName}
              helperText={formData.errors.firstName}
              size="small"
              sx={{ mb: 1, maxWidth: '400px' }}
            />
            <TextField
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleFormChange}
              error={!!formData.errors.lastName}
              helperText={formData.errors.lastName}
              size="small"
              sx={{ mb: 1, maxWidth: '400px' }}
            />
            <TextField
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleFormChange}
              error={!!formData.errors.email}
              helperText={formData.errors.email}
              size="small"
              sx={{ mb: 1, maxWidth: '400px' }}
            />
            <MuiTelInput
              name="phone"
              label="Phone Number"
              className="contact-phone-input"
              variant="filled"
              value={formData.phone}
              forceCallingCode
              defaultCountry="US"
              continents={['NA', 'EU']}
              onChange={newValue => handleFormChange({ target: { name: 'phone', value: newValue.replace(/\s/g, '') } })}
              error={!!formData.errors.phone}
              helperText={formData.errors.phone}
              size="small"
              sx={{ mb: 1, maxWidth: '400px' }}
            />
            <Select id="subject-select" name="subject" label="Issue/Subject" value={formData.subject} onChange={handleFormChange} size="small" sx={{ mb: 1, maxWidth: '400px' }}>
              {questions.map((question, index) => {
                return (
                  <MenuItem key={`option-${index}`} value={question}>
                    {question}
                  </MenuItem>
                );
              })}
            </Select>
            <TextField
              name="comments"
              label="Comments"
              multiline
              rows={10}
              value={formData.comments}
              onChange={handleFormChange}
              error={!!formData.errors.comments}
              helperText={formData.errors.comments}
              size="small"
            />
            <Container sx={{ mt: 2, mb: 2 }}>
              <Button color="secondary" onClick={handleClear} size="medium" variant="contained" disabled={isSendingEmail} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleSubmit} size="medium" variant="contained" disabled={isSendingEmail} sx={{ mr: 2 }} type="submit">
                Submit
              </Button>
            </Container>
          </Stack>
        </>
      )}
      {contactInfo?.email ? (
        <Typography data-testid="contact-message" color="text.primary">
          For immediate assistance with a particular issue, please email us at <Link href={`mailto:${contactInfo.email}`}>{contactInfo.email}</Link>
          {contactInfo?.phoneNumber && <> or call {contactInfo.phoneNumber}</>}.
        </Typography>
      ) : (
        <Typography data-testid="fallback-contact-message" color="text.primary">
          For immediate assistance with a particular issue, please contact your tracking supplier.
        </Typography>
      )}
    </Container>
  );
};

Contact.propTypes = {};

const validateEmail = email => {
  return (
    String(email)
      .toLowerCase()
      // eslint-disable-next-line sonarjs/regex-complexity
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  );
};

export default Contact;
