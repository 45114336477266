import { createEvent, createStore, EventCallable, sample } from 'effector';
import { persist } from 'effector-storage/query';
import React from 'react';

export const createAdminUsersPageModel = ({ tabs, openedTabKey, $$manageUserRolesModal, $$manageUserAssignedToRoleModal }: {
  tabs: { name: string; title: string; component: React.ReactElement }[];
  openedTabKey: string;
  $$manageUserRolesModal: object;
  $$manageUserAssignedToRoleModal: object;
}) => {

  const tabSelectedByIndex: EventCallable<number> = createEvent();

  const $openedTab = createStore(/** @type {TabName} */ ('manage-users'));

  persist({ store: $openedTab, key: openedTabKey });

  sample({
    clock: tabSelectedByIndex,
    fn: index => tabs[index].name,
    target: $openedTab
  });

  return {
    $openedTab: $openedTab.map(tab => tabs.map(({ name }) => name).indexOf(tab)),

    tabSelectedByIndex,

    tabs,

    $$manageUserRolesModal,

    $$manageUserAssignedToRoleModal,
  };
};