import { $$user } from '../../../../services/UserService/UserService';
import { createManageUserRolesModal } from '../../../../components/AdminUsersPage/ManageUserRolesModal/createManageUserRolesModal.ts';
import { $$roles } from '../../../../services/RolesService/model.ts';

export const $$resellerManageUsersRolesModal = createManageUserRolesModal({
  getUserRolesFx: $$user.getUserRolesFx,
  assignRolesToUserFx: $$user.assignRolesToUserFx,
  getUserByIdFx: $$user.getUserByIdFx,
  getManageableRolesFx: $$roles.getManageableRolesFx,
  deleteRoleFromUserFx: $$user.deleteRoleFromUserFx,
  getUsersFx: $$user.getUsersFx,
});