import { DeleteForever } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@ghs/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';
import { useUnit } from 'effector-react';
import { isEmpty } from 'lodash';

/**
 * UserRolesTable component
 *
 * @returns {React.ReactNode} UserRolesTable
 */
export default function UserRolesTable({ $$manageUserRolesModal }) {
  const dataGridProps = $$manageUserRolesModal.$$rolesDataGrid.useDataGrid();
  const isManagingCurrentUser = useUnit($$manageUserRolesModal.$isManagingCurrentUser);

  const customerTableColumns: GridColDef<Roles.Role, never, never>[] = [
    { field: 'name', headerName: 'Role', minWidth: 160, flex: 4 },
    { field: 'description', headerName: 'Description', minWidth: 160, flex: 6 },
    {
      field: 'actions',
      type: 'actions',
      editable: false,
      headerName: 'Actions',
      headerAlign: 'center',
      getActions: ({ id }) => [
        <IconButton key="delete" onClick={() => $$manageUserRolesModal.openDeleteConfirmation(id + '')} disabled={isManagingCurrentUser}>
          <DeleteForever fontSize="small" color={isManagingCurrentUser ? 'disabled' : 'warning'} />
        </IconButton>
      ]
    }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: isEmpty(dataGridProps.rows) ? 250 : undefined }}>
      <DataGrid
        {...dataGridProps}
        columns={customerTableColumns}
        disableRowSelectionOnClick
        editMode="row"
        getRowId={row => row.id}
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pagination={false}
        hideFooter={true}
        autosizeOnMount={false} // vitest is failing beause of this prop
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: true
        }}
        sx={DataGridStylesWithoutSelectionOverride}
      />
    </Box>
  );
}
