import { attach, createEvent, createStore, restore, sample, EventCallable, Store } from 'effector';
import { $$snackbar } from '../../SnackbarRoot/model';
import { spread } from 'patronum';
import { createDialog } from '../../../util/createDialog';
import createDataGridModel from '../../../util/createDataGridModel';
import { $$auth } from '../../../services/AuthService/model';
import { persist } from 'effector-storage/local';
import { createDeleteConfirmationDialogArg } from './utils.ts';
import { $$confirmationDialog } from '../../ConfirmationDialog/model';
import { VOID } from '../../../util/JsonUtils';
import { ManageUserRolesModalProp } from './type.ts';
import { keyBy, values } from 'lodash';

export const createManageUserRolesModal = ({
  getUserRolesFx,
  assignRolesToUserFx,
  getUserByIdFx,
  getManageableRolesFx,
  deleteRoleFromUserFx,
  getUsersFx
}: ManageUserRolesModalProp) => {
  const _getUsersFx = attach({ effect: getUsersFx });
  const _getUserByIdFx = attach({ effect: getUserByIdFx });
  const _assignRolesToUserFx = attach({ effect: assignRolesToUserFx });
  const _deleteRoleFromUserFx = attach({ effect: deleteRoleFromUserFx });
  const _getManageableRolesFx = attach({ effect: getManageableRolesFx });

  const $users = createStore(/** @type {Users.User[]} */ []);

  const dialog: DialogFactory<Partial<{ userId: Users.User['userId'] }>> = createDialog();

  const rolesToAddChanged: EventCallable<Roles.Role['id'][]> = createEvent();

  const openDeleteConfirmation: EventCallable<Roles.Role['id']> = createEvent();

  const deleteConfirmationSkipChecked: EventCallable<boolean> = createEvent();

  const deleteConfirmed: EventCallable<Roles.Role['id']> = createEvent();

  const saveClicked: EventCallable<void> = createEvent();

  const refetch: EventCallable<void> = createEvent();

  const $rolesOptions = createStore(/** @type {Roles.Role[]} */ []).reset(dialog.close);

  const $deleteConfirmationSkipped = createStore(/** @type {boolean} */ false);

  const $rolesToAdd = restore(rolesToAddChanged, []).reset(sample({ clock: [dialog.close, _assignRolesToUserFx.done] }));

  const getFreshRowsFx = attach({
    source: dialog.$state.map(state => state?.userId || ''),
    /** @type {(params: void, filters: Users.User['userId']) => object} */
    mapParams: (_, userId): object => userId,
    effect: getUserRolesFx
  });

  const $$rolesDataGrid = createDataGridModel({
    type: 'client',
    effect: getFreshRowsFx,
    refetch
  });

  const $isManagingCurrentUser = sample({
    source: {
      authorizedUserId: $$auth.$user.map(user => user?.sub || null),
      openedUserId: dialog.$state.map(state => state?.userId || null)
    },
    fn: ({ authorizedUserId, openedUserId }) => {
      return authorizedUserId === openedUserId;
    }
  });

  const $user: Store<Users.User | null> = sample({
    source: {
      users: $users,
      userId: dialog.$state.map(params => params?.userId || null),
      isManagingCurrentUser: $isManagingCurrentUser,
      authorizedUser: $$auth.$user.map(user => user || null)
    },
    fn: ({ users, userId, isManagingCurrentUser, authorizedUser }) => {
      const resultUser = users.find(user => user.userId === userId) || null;
      if (resultUser && isManagingCurrentUser) {
        // There are at least 3 places where the user avatar is displayed: Top right user menu, "My Roles" tab, "Manage user" modal (when clicking on the current user)
        // In the first 2 places, the information of the current user comes from Auth0 directly. However, in the user modal, the information comes from database (the user
        // may not even exist in Auth0 at that point).
        // So in order to at least display the same avatar for the current user in all places, we overwrite the user detail here only for the current user.
        return {
          ...resultUser,
          firstName: authorizedUser.given_name,
          lastName: authorizedUser.family_name,
          avatarUrl: authorizedUser.picture
        };
      }
      return resultUser;
    }
  });

  persist({ store: $deleteConfirmationSkipped, key: 'gh:admin-users-roles:skip-role-delete-confirm' });

  sample({
    clock: dialog.open.map(params => params?.userId || null),
    filter: Boolean,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    target: [_getUserByIdFx, _getManageableRolesFx.prepend(() => {})]
  });

  sample({
    clock: _getManageableRolesFx.doneData,
    target: $rolesOptions
  });

  sample({
    clock: deleteConfirmationSkipChecked,
    target: $deleteConfirmationSkipped
  });

  sample({
    // @ts-ignore
    source: {
      roles: $rolesOptions,
      users: $users,
      userId: dialog.$state.map(params => params?.userId || ''),
      shouldSkip: $deleteConfirmationSkipped
    },
    clock: openDeleteConfirmation,
    fn: ({ userId, shouldSkip, roles, users }, roleId) =>
      shouldSkip
        ? { deleteConfirmed: userId }
        : {
            confirm: createDeleteConfirmationDialogArg({
              roles,
              users,
              roleId,
              userId,
              deleteConfirmationSkipChecked,
              deleteConfirmed
            })
          },
    target: spread({ deleteConfirmed, confirm: $$confirmationDialog.open })
  });

  sample({
    source: dialog.$state.map(params => params?.userId || ''),
    clock: deleteConfirmed,
    fn: (userId, roleId) => ({ userId, roleId }),
    target: _deleteRoleFromUserFx
  });

  if ($$rolesDataGrid.removeRowsByIds) {
    sample({
      clock: _deleteRoleFromUserFx.done.map(({ params: { roleId } }) => [roleId]),
      target: $$rolesDataGrid.removeRowsByIds
    });
  }

  sample({
    clock: _deleteRoleFromUserFx.done,
    fn: () => ({ message: 'Role deleted from user', severity: 'success' }),
    target: $$snackbar.open
  });

  sample({
    clock: _deleteRoleFromUserFx.fail,
    fn: ({ error }) => ({
      message: error?.response?.data?.message ?? 'Error deleting role from user',
      severity: 'error',
      autoHideDuration: 6000
    }),
    target: $$snackbar.open
  });

  sample({
    source: {
      roles: $rolesToAdd,
      userId: dialog.$state.map(params => params?.userId || '')
    },
    clock: saveClicked,
    fn: ({ roles, userId }) =>
      roles.length
        ? {
            saveFx: /** @type  {{ userId: Users.User['userId']; roles:Roles.Role['id'][] }} */ {
              userId,
              roles
            }
          }
        : { close: VOID },
    target: spread({ saveFx: _assignRolesToUserFx, close: dialog.close })
  });

  sample({
    clock: _assignRolesToUserFx.done,
    fn: () => ({
      snackbar: { message: 'Role(s) assigned to user', severity: 'success' },
      refetch: VOID
    }),
    target: spread({
      snackbar: $$snackbar.open,
      refetch: refetch
    })
  });

  sample({
    clock: _assignRolesToUserFx.fail,
    fn: ({ error }) => ({
      message: error?.response?.data?.message ?? 'Error assigning role to user',
      severity: 'error',
      autoHideDuration: 6000
    }),
    target: $$snackbar.open
  });

  sample({
    source: $users,
    clock: _getUsersFx.doneData,
    fn: (users, user) => values({ ...keyBy(users, 'userId'), [user.userId]: user }),
    target: $users
  });

  sample({
    source: $users,
    clock: _getUserByIdFx.doneData,
    fn: (users, user) => values({ ...keyBy(users, 'userId'), [user.userId]: user }),
    target: $users
  });

  return {
    ...dialog,

    $$rolesDataGrid,

    $user,
    $rolesOptions,
    $rolesToAdd,
    $isManagingCurrentUser,

    rolesToAddChanged,
    openDeleteConfirmation,
    saveClicked
  };
};
