import { $$roles } from '../../../../services/RolesService/model.ts';
import { createManageUsersAssignedToRoleModal } from '../../../../components/AdminUsersPage/ManageUsersAssignedToRoleModal/createManageUsersAssignedToRoleModal.ts';
import { $$user } from '../../../../services/UserService/UserService.ts';

export const $$resellerManageUsersAssignedToRoleModal = createManageUsersAssignedToRoleModal({
  getUsersForRole: $$roles.getUsersForRole,
  deleteUsersFromRoleFx: $$roles.deleteUsersFromRoleFx,
  assignUserToRoleFx: $$roles.assignUserToRoleFx,
  getUsersFx: $$user.getUsersFx,
  $roles: $$roles.$roles,
});