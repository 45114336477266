import { GridColDef } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';
import { DataGrid } from '@ghs/components';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';

type DataAccessUsersTableProps<T extends Reseller.User | Reseller.LineOfBusiness> = {
  dataGridProps: ReturnType<DataGridFactory<T>['useDataGrid']>;
  columns: GridColDef<Reseller.User | Reseller.LineOfBusiness>[];
};

export default function DataAccessUsersTable<T extends Reseller.User | Reseller.LineOfBusiness>(props: DataAccessUsersTableProps<T>) {
  const { dataGridProps, columns } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: isEmpty(dataGridProps.rows) ? 250 : undefined }}>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        disableRowSelectionOnClick
        editMode="row"
        getRowId={row => row.id}
        initialState={{ columns: { columnVisibilityModel: { id: false } }, pagination: { paginationModel: { pageSize: 10 } } }}
        pagination
        defaultPagination={true}
        pageSizeOptions={[5, 10]}
        autosizeOnMount={false} // vitest is failing beause of this prop
        autosizeOptions={{ includeOutliers: true, includeHeaders: true }}
        sx={{
          ...DataGridStylesWithoutSelectionOverride,
          '.MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%'
          }
        }}
      />
    </Box>
  );
}

DataAccessUsersTable.propTypes = {
  dataGridProps: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
};
