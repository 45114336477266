import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import SelectUserAutocomplete from '../../SelectUserAutocomplete/SelectUserAutocomplete';
import UserRolesTable from './UserRolesTable.tsx';

/**
 * ManageUsersAssignedToRoleModal component
 *
 * @returns {React.ReactNode} ManageUsersAssignedToRoleModal
 */
export default function ManageUsersAssignedToRoleModal({ $$manageUsersAssignedToRoleModal }): React.ReactNode {
  const open = useUnit($$manageUsersAssignedToRoleModal.$isOpen);
  const state = useUnit($$manageUsersAssignedToRoleModal.$state);
  const role = useUnit($$manageUsersAssignedToRoleModal.$role);
  const usersToAdd = useUnit($$manageUsersAssignedToRoleModal.$usersToAdd);

  if (!state || !role) {
    return null;
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => {
        $$manageUsersAssignedToRoleModal.close();
      }}
      PaperProps={{ sx: { borderRadius: 2, padding: 2 } }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
        <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
          {role.name}
        </Typography>
        <IconButton onClick={() => $$manageUsersAssignedToRoleModal.close()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DialogContentText sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <UserRolesTable $$manageUsersAssignedToRoleModal={$$manageUsersAssignedToRoleModal} />
          <Typography variant="body1" color="text.primary">
            Select users to assign to this role
          </Typography>
          <SelectUserAutocomplete selectedUsers={usersToAdd} onSelectedUsersUpdated={$$manageUsersAssignedToRoleModal.userSelected} getUsersFx={$$manageUsersAssignedToRoleModal.getUsersFx} />
        </DialogContentText>
        <DialogActions>
          <Button variant="outlined" onClick={() => $$manageUsersAssignedToRoleModal.close()}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => $$manageUsersAssignedToRoleModal.saveClicked()} disabled={usersToAdd.length === 0}>
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
