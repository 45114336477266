import axios from 'axios';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import qs from 'qs';
import '../pages/AddressVerification/utils/TypeDefs';

const FETCH_TIMEOUT_MS = 299000;

const $getToken = createStore<(() => Promise<string>) | null>(null);

export const getTokenChanged = createEvent<(() => Promise<string>) | null>();

sample({
  clock: getTokenChanged,
  target: $getToken
});

export const ghApi = axios.create({
  baseURL: import.meta.env.VITE_API_SERVER_URL,
  timeout: FETCH_TIMEOUT_MS,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' })
});

const getTokenFx = attach({
  source: $getToken,
  effect: createEffect<(typeof $getToken)['__'], string | null>(async getToken => {
    if (getToken !== null && typeof getToken === 'function') {
      return getToken();
    }
    return null;
  })
});

ghApi.interceptors.request.use(async config => {
  const token = await getTokenFx();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const sisenseApi = axios.create({
  baseURL: `${import.meta.env.VITE_BI_SERVER_URL}/api/v1`,
  withCredentials: true
});

/**
 * Call VerifyAQ API Endpoint.
 *
 * @param {object} verifyAQRequestObject - Object containing address information.
 * @param {string} verifyAQRequestObject.company - Company name.
 * @param {string} verifyAQRequestObject.street - Street address.
 * @param {string} verifyAQRequestObject.street2 - Additional street address information.
 * @param {string} verifyAQRequestObject.urbanization - Urbanization information.
 * @param {string} verifyAQRequestObject.city - City.
 * @param {string} verifyAQRequestObject.state - State.
 * @param {string} verifyAQRequestObject.zip - ZIP code.
 * @param {string} verifyAQRequestObject.zipPlusFour - ZIP+4 code.
 * @param {boolean} verifyAQRequestObject.standardizeStreets - Indicates whether to standardize street addresses (Set to true in all cases).
 * @param {boolean} verifyAQRequestObject.useMixedCase - Indicates whether to use mixed case for address information (optional, default is false).
 * @param {boolean} verifyAQRequestObject.retainAddressLocation - Indicates whether to retain address location information (optional, default is false).
 * @param {boolean} verifyAQRequestObject.suiteLinkToAddress2 - Links a suite to the second address line (optional).
 * @returns {VerifyAQResponseDef} Verify AQ API request results.
 * @comment Refactor to use ghApi.get instead
 */

export const fetchVerifyAQ = async verifyAQRequestObject => {
  // prettier-ignore
  return ghApi.get(`/addressQuality`, { // NOSONAR
    params: { ...Object.fromEntries(Object.entries(verifyAQRequestObject).filter(([, value]) => value !== '')) }
  }).then(response => response.data)
    .catch(e => {
      throw new HTTPResponseError(e);
    });
};

/** @deprecated Use ghApi.get instead */
export const fetchDataAxios = async (url, accessToken, config = {}) => {
  const response = await axios.get(url, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    ...config
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new HTTPResponseError(response);
  }
};

/** @deprecated Use ghApi.post instead */
export const postDataAxios = async (url, accessToken, data, config = {}) => {
  const response = await axios.post(url, data, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    ...config
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new HTTPResponseError(response);
  }
};

export class HTTPResponseError extends Error {
  constructor(response) {
    super(`HTTP Error Response: ${response.status} ${response.statusText}`);
    this.response = response;
  }
}
