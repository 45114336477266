import { Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import React, { Fragment } from 'react';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import ManageUserRolesModal from '../../../components/AdminUsersPage/ManageUserRolesModal/ManageUserRolesModal.tsx';
import ManageUsersAssignedToRoleModal
  from '../../../components/AdminUsersPage/ManageUsersAssignedToRoleModal/ManageUsersAssignedToRoleModal.tsx';
import SnackbarRoot from '../../../components/SnackbarRoot/SnackbarRoot';
import TutorialVideoButton from '../../../components/TutorialVideosModal/TutorialVideoButton.tsx';
import { $$ghsAdminUsersPage } from './model.ts';
import { $$tutorialVideos } from '../../../components/TutorialVideosModal/model.ts';

/**
 * Component for displaying the form to manage external users.
 *
 * @returns {React.ReactNode} AdminUsers component
 */
export default function GhsAdminUsersPage(): React.ReactNode {
  const openedTabNumber = useUnit($$ghsAdminUsersPage.$openedTab);

  return (
    <>
      <ConfirmationDialog />
      <ManageUserRolesModal  $$manageUserRolesModal={$$ghsAdminUsersPage.$$manageUserRolesModal} />
      <ManageUsersAssignedToRoleModal $$manageUsersAssignedToRoleModal={$$ghsAdminUsersPage.$$manageUserAssignedToRoleModal} />
      <TutorialVideoButton url={$$tutorialVideos.links['ST_ADMIN:ROLES_AND_PERMISSIONS']} />

      <Container id="admin-users-container">
        <Stack sx={{ mb: 3 }}>
          <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
            Roles and Permissions
          </Typography>
          <Typography variant="subtitle2" color="text.primary" sx={{ mb: 2, display: openedTabNumber === 2 ? 'none' : 'flex' }}>
            Only users that have logged into the Portal will be visible in this page.
          </Typography>
        </Stack>
        <Tabs value={openedTabNumber} onChange={(_, index) => $$ghsAdminUsersPage.tabSelectedByIndex(index)} aria-label="basic tabs example" sx={{ mb: 4 }}>
          {$$ghsAdminUsersPage.tabs.map(tab => (
            <Tab key={tab.name} label={tab.title} sx={{ textTransform: 'none' }} />
          ))}
        </Tabs>
        {$$ghsAdminUsersPage.tabs.map((tab, index) => (
          <Fragment key={tab.name}>{index === openedTabNumber && tab.component ? tab.component : null}</Fragment>
        ))}
        <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
      </Container>
    </>
  );
}
