import { createEffect } from 'effector';
import { groupBy, values } from 'lodash';
import { omit, set } from 'ramda';

/**
 * Parses a JSON string into an object, or returns a fallback value if parsing fails.
 *
 * @param {string} jsonString - The JSON string to parse.
 * @param {*} fallbackValue - The value to return if parsing fails.
 * @returns {*} The parsed JSON object if successful, or the fallback value if parsing fails.
 */
export const parseJSONWithFallback = (jsonString, fallbackValue) => {
  try {
    return JSON.parse(jsonString);
  } catch (_) {
    return fallbackValue;
  }
};

/**
 * Converts an object to a JSON string, or returns a fallback value if conversion fails.
 *
 * @param {object} obj - The object to convert to a JSON string.
 * @returns {string} The JSON string if successful, or the fallback value if conversion fails.
 */
export function objectFlatten(obj) {
  Object.keys(obj)
    ?.filter?.(key => typeof obj[key] === `object`)
    .forEach?.(key => {
      Object.keys(obj?.[key] ?? {})?.forEach?.(innerKey => {
        obj[`${key}.${innerKey}`] = obj[key][innerKey];
      });
      obj = omit(obj, key);
    });
  return Object.keys(obj)?.filter?.(key => typeof obj[key] === `object`).length > 0 ? objectFlatten(obj) : obj;
}

/**
 * Converts an object to a JSON string, or returns a fallback value if conversion fails.
 *
 * @param {object} obj - The object to convert to a JSON string.
 * @returns {object} The JSON string if successful, or the fallback value if conversion fails.
 */
export function flattenToObject(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    set(acc, key, value);
    return acc;
  }, /** @type {object} */ ({}));
}

/**
 * Converts an object to a JSON string, or returns a fallback value if conversion fails.
 *
 * @param {object} obj - The object to convert to a JSON string.
 * @returns {object} The JSON string if successful, or the fallback value if conversion fails.
 */
export function treeShakeObject(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => Boolean(value)));
}

/**
 * Converts an object to a JSON string, or returns a fallback value if conversion fails.
 *
 * @param {string} string - The string to hash.
 * @returns {number} The hash code of the string.
 */
export function hashCode(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    const code = string.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Converts an object to a JSON string, or returns a fallback value if conversion fails.
 *
 * @param {number} x - The number to format.
 * @returns {string} The formatted number.
 */
export function numberWithCommas(x) {
  return x.toLocaleString('en-US', { maximumFractionDigits: 2 });
}

/**
 * Converts an object to a JSON string, or returns a fallback value if conversion fails.
 *
 * @template T
 * @param {object} params - The parameters for the effect.
 * @param {string} params.filename - The name of the file to save.
 * @param {T[]} params.data - The array to save as a CSV file.
 * @param {Record<keyof T, string>} params.columns - The columns to include in the CSV file.
 * @returns {string} The formatted number.
 */
export const saveArrayAsCsvFx = createEffect(params => {
  const { filename, data, columns } = params;
  const csv = [
    Object.values(columns).join(','),
    ...data.map(item =>
      Object.keys(columns)
        .map(column => item[column] ?? '')
        .join(',')
    )
  ].join('\n');
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
  URL.revokeObjectURL(url);
});

export const emailRegex = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\\.]*)[A-Z0-9_'+\\-]@([A-Z0-9][A-Z0-9\\-]*\.)+[A-Z]{2,}$/i;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const VOID = (() => {})();

/**
 * Merges two arrays of objects by multiple keys.
 *
 * @template T
 * @param {T[]} array1 - The first array to merge.
 * @param {T[]} array2 - The second array to merge.
 * @param {(keyof T)[]} keys - The keys to merge by.
 * @returns {T[]} The merged array.
 */
export const mergeByMultipleKeys = (array1, array2, keys) => {
  const g1 = groupBy(array1, item => keys.map(key => String(item[key])).join());
  const g2 = groupBy(array2, item => keys.map(key => String(item[key])).join());
  return values({ ...g1, ...g2 }).flat();
};
