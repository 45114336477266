import { $$personaService } from '../../../services/PersonaService/model';
import { attach, createEvent, createStore, EventCallable, sample } from 'effector';
import { treeShakeObject } from '../../../util/JsonUtils';
import createDataGridModel from '../../../util/createDataGridModel';
import { debounce } from 'patronum';
import { $$reseller } from '../../../services/ResellerService/model';
import { persist } from 'effector-storage/query';
import { pipe } from 'ramda';
import qs from 'qs';
import { ManageUsersTab } from './type.ts';


export const createManageUsersTabModel = ({ getUsersFx } : ManageUsersTab) => {

  //-------------------------------- Stores --------------------------------
  const $isResellerUser = sample({
    source: $$personaService.$activePersona,
    fn: persona => !persona?.customerId
  });
  const $customers = createStore<Reseller.Customer[]>([]);
  const $filters = createStore<{ search?: string; customerId?: string }>({});

//-------------------------------- Events --------------------------------
  const tableMounted: EventCallable<boolean> = createEvent();
  const setFilterByKey: EventCallable<{ key: keyof (typeof $filters)['__']; value: unknown }> = createEvent();
//-------------------------------- Effects --------------------------------
  const getFreshRowsFx = attach({
    source: $filters,
    /** @type {(params: DataGrid.ServerSideParams, filters: { customerId: Reseller.Customer['id']; search: string }) => (typeof $$user.getUsersFx)['done']['__']['params']} */
    mapParams: (pagination, filters) => treeShakeObject({ ...pagination, ...filters }),
    effect: getUsersFx
  });

  const $$usersDataGrid = createDataGridModel({
    type: 'server',
    effect: getFreshRowsFx,
    refetch: debounce({ source: $filters.updates, timeout: 500 }),
    rowIdField: 'userId'
  });

  sample({
    source: $isResellerUser,
    clock: tableMounted.filter({ fn: Boolean }).map(() => ({})),
    filter: isReseller => isReseller, // Only load customers for reseller level users
    target: $$reseller.getCustomersFx
  });

  sample({
    clock: $$reseller.getCustomersFx.doneData,
    fn: customers => customers.map(customer => ({ id: customer.id, name: customer.name })).sort((a, b) => a.name.localeCompare(b.name)),
    target: $customers
  });

  sample({
    source: $filters,
    clock: setFilterByKey,
    fn: (filters, { key, value }) => ({ ...filters, [key]: value }),
    target: $filters
  });

  persist({
    store: $filters,
    key: 'manage-users-filters',
    serialize: pipe(treeShakeObject, qs.stringify),
    deserialize: qs.parse
  });

  return {
    ...$$usersDataGrid,
    $isResellerUser,
    $filters: $filters.map(filters => ({ ...filters })),
    $customers: $customers.map(d => d),

    tableMounted,
    setFilterByKey
  }
}