import { Link } from '@ghs/components';
import { Box, Container, Typography, Grid } from '@mui/material';
import { useUnit } from 'effector-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { renderToString } from 'react-dom/server';
import { $$splResultsPage } from '../pages/SplResultsPage/model';

/**
 * Determine if street view is available for lat/long coordinates by querying the Google Maps API.
 *
 * @param {object} latLng the coordinates to check for street view
 * @returns {Promise<boolean>} whether street view is available
 */
const isStreetViewAvailable = async latLng => {
  const streetViewClient = new window.google.maps.StreetViewService();
  const STREET_VIEW_MAX_DISTANCE = 50;
  try {
    await streetViewClient.getPanoramaByLocation(latLng, STREET_VIEW_MAX_DISTANCE);
    return true;
  } catch (_) {
    return false;
  }
};

/**
 * Geocode an address.
 *
 * @param {object} options geocode options
 * @returns {Promise<any>} the geocode result if successful
 */
const geocode = async options => {
  const geocoder = new window.google.maps.Geocoder();

  try {
    const { results } = await geocoder.geocode(options);
    return results[0];
  } catch (e) {
    console.warn(`Error geocoding ${JSON.stringify(options)}: `, e);
    return null;
  }
};

/**
 * Attach handler to link in InfoWindow to open street view.
 *
 * @param {object} map the Google Maps instance
 * @param {object} infoWindow the InfoWindow containing the link
 * @param {object} latLng the street view coordinates
 * @param {string} streetViewLinkId the id of the element to attach the handler to
 */
const attachStreetViewHandler = (map, infoWindow, latLng, streetViewLinkId) => {
  const handleStreetView = async event => {
    event.preventDefault();

    map.getStreetView().setPosition(latLng);
    map.getStreetView().setVisible(true);
    map.getStreetView().set('addressControl', false);
  };

  // Since `renderToString` does not allow for attaching onClick handlers, attach the handler here
  window.google.maps.event.addListener(infoWindow, 'domready', () => (document.getElementById(streetViewLinkId).onclick = handleStreetView));
};

/**
 * DeliveryMap component for plotting scans for a mail piece on Google Maps.
 *
 * @returns {JSX.Element} DeliveryMap component
 */
export function DeliveryMap() {
  const acsEvents = useUnit($$splResultsPage.$acsEvents);
  const mailPiece = useUnit($$splResultsPage.$mailPiece);
  const scans = useUnit($$splResultsPage.$scans);

  const ref = React.useRef();
  const lineSymbol = {
    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
  };
  const mapBounds = new window.google.maps.LatLngBounds();
  let openInfoWindow;
  const handleMarkerClick = (infoWindow, map, marker) => {
    infoWindow.open(map, marker);
    if (infoWindow !== openInfoWindow) {
      openInfoWindow?.close();
      openInfoWindow = infoWindow;
    } else {
      infoWindow.close();
      openInfoWindow = null;
    }
  };

  React.useEffect(() => {
    const plotMap = async () => {
      if (scans && scans.length) {
        const map = new window.google.maps.Map(ref.current);
        const latLngs = [];

        for (let scan of scans) {
          const isDeliveryEvent = scan?.details.trim() === 'Delivered to Mailbox';
          const icon = isDeliveryEvent ? './mailTruck.png' : './envelope.png';
          let includeStreetView = false;
          let latLng;
          let geocodeResult;
          const addressToGeocode = `${mailPiece.address}, ${mailPiece.city}, ${mailPiece.state} ${mailPiece.zip}`;
          // Use geocoder for `Delivered to Mailbox` events if the Zip4 in the scan event matches the Zip4 in the mail details
          if (isDeliveryEvent && scan.destination.includes(mailPiece.zip) && (geocodeResult = await geocode({ address: addressToGeocode }))) {
            latLng = geocodeResult.geometry.location;
            includeStreetView = await isStreetViewAvailable(latLng);
          } else {
            if (!scan.latitude || !scan.longitude) {
              continue;
            }
            latLng = { lat: parseFloat(scan.latitude), lng: parseFloat(scan.longitude) };
          }
          latLngs.push(latLng);
          mapBounds.extend(latLng);
          const marker = new window.google.maps.Marker({
            position: latLng,
            map,
            icon: icon
          });

          const infoWindow = new window.google.maps.InfoWindow({
            maxWidth: 400
          });
          const streetViewLinkId = `street-view-scan${scan.id}`;
          if (geocodeResult) {
            infoWindow.setContent(renderToString(<GeocodedScanInfoWindowContent geocodeResult={geocodeResult} includeStreetView={includeStreetView} streetViewLinkId={streetViewLinkId} />));
          } else {
            infoWindow.setContent(renderToString(<ScanInfoWindowContent data={scan} />));
          }

          if (includeStreetView) {
            attachStreetViewHandler(map, infoWindow, latLng, streetViewLinkId);
          }

          window.google.maps.event.addListener(marker, 'click', () => handleMarkerClick(infoWindow, map, marker));
        }

        // prettier-ignore
        // eslint-disable-next-line sonarjs/constructor-for-side-effects
        new window.google.maps.Polyline({ // NOSONAR
          path: latLngs,
          icons: [{ icon: lineSymbol, offset: '100%' }],
          map: map,
          strokeColor: 'blue'
        });

        if (acsEvents && acsEvents.length) {
          for (const acsEvent of acsEvents) {
            let geocodeResult;
            const fullAddress = `${acsEvent.newAddress}, ${acsEvent.newCity}, ${acsEvent.newState} ${acsEvent.new5Zip}-${acsEvent.newPlus4}`;
            if (acsEvent.newAddress && acsEvent.newAddress.trim() && (geocodeResult = await geocode({ address: fullAddress }))) {
              const latLng = geocodeResult.geometry.location;

              mapBounds.extend(latLng);
              const marker = new window.google.maps.Marker({
                position: latLng,
                map,
                icon: './acsEvent.png'
              });

              const infoWindow = new window.google.maps.InfoWindow({
                maxWidth: 400
              });
              const hasStreetView = await isStreetViewAvailable(latLng);
              const streetViewLinkId = `street-view-${acsEvent.new5Zip}-${acsEvent.newPlus4}`;
              infoWindow.setContent(renderToString(<AcsEventInfoWindowContent data={acsEvent} hasStreetView={hasStreetView} streetViewLinkId={streetViewLinkId} />));

              if (hasStreetView) {
                attachStreetViewHandler(map, infoWindow, latLng, streetViewLinkId);
              }

              window.google.maps.event.addListener(marker, 'click', () => handleMarkerClick(infoWindow, map, marker));
            }
          }
        }

        map.fitBounds(mapBounds);

        // Adjust zoom not a maximum default value. This will take effect when there's a single point to display or all
        // the points are very close to each other. We don't want to use "minZoom" because that would prevent the user
        // from zooming in more
        const listener = window.google.maps.event.addListener(map, 'idle', () => {
          if (map.getZoom() > 15) {
            map.setZoom(15);
          }
          // Remove the listener to prevent it from running again.
          window.google.maps.event.removeListener(listener);
        });
      }
    };

    plotMap();
  }, [scans, acsEvents, mailPiece]);

  return !scans || !scans.length ? <Container /> : <Box ref={ref} id="map" height="500px" />;
}

const SCAN_PROP_TYPE = PropTypes.shape({
  destination: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  icon: PropTypes.element,
  scanDateTime: PropTypes.string,
  scanSiteZip: PropTypes.string,
  scanCityState: PropTypes.string,
  details: PropTypes.string,
  travelDays: PropTypes.number
});

DeliveryMap.propTypes = {
  scans: PropTypes.arrayOf(SCAN_PROP_TYPE),
  acsEvents: PropTypes.arrayOf(PropTypes.object),
  mailDetails: PropTypes.object
};

const ScanInfoWindowContent = ({ data }) => {
  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <Typography component="b">Scan Date/Time: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{data.scanDateTime}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Scan Site ZIP: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{data.scanSiteZip}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Scan City/State: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{data.scanCityState}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Activity: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{data.details}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Travel Days: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{data.travelDays}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

ScanInfoWindowContent.propTypes = {
  /** Scan data. */
  data: SCAN_PROP_TYPE
};

const GeocodedScanInfoWindowContent = ({ geocodeResult, includeStreetView, streetViewLinkId }) => {
  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <Typography component="b">Address: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{geocodeResult.formatted_address}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Types: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{geocodeResult.types.join(', ')}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Location: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{geocodeResult.geometry.location.toString()}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Location type: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{geocodeResult.geometry.location_type}</Typography>
        </Grid>
        {geocodeResult.partial_match ? (
          <React.Fragment>
            <Grid item xs={5}>
              <Typography component="b">Partial match: </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography component="span">Yes</Typography>
            </Grid>
          </React.Fragment>
        ) : null}
        {includeStreetView ? (
          <React.Fragment>
            <Grid item xs={5}>
              <Typography component="b">Street View: </Typography>
            </Grid>
            <Grid item xs={7}>
              <Link id={streetViewLinkId} href="#">
                {'click here'}
              </Link>
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>
    </Box>
  );
};

GeocodedScanInfoWindowContent.propTypes = {
  /** Result from Google Maps geocoder. */
  geocodeResult: PropTypes.object,
  /** Whether to show a link to street view. */
  includeStreetView: PropTypes.bool,
  /** Id to provide to the link to show street view. */
  streetViewLinkId: PropTypes.string
};

const AcsEventInfoWindowContent = ({ data, hasStreetView, streetViewLinkId }) => {
  const newAddress = `${data.newAddress}, ${data.newCity}, ${data.newState} ${data.new5Zip}-${data.newPlus4}`;

  return (
    <Box>
      <Typography component="b">ACS Event</Typography>
      <br />
      <br />
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <Typography component="b">Event Date: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{data.dateReceived}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Reason: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{data.reason}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">New Address: </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="span">{newAddress}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="b">Street View: </Typography>
        </Grid>
        <Grid item xs={7}>
          {hasStreetView ? (
            <Link id={streetViewLinkId} href="#">
              {'click here'}
            </Link>
          ) : (
            <Typography component="span">Not available</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

AcsEventInfoWindowContent.propTypes = {
  /** The ACS event data. */
  data: PropTypes.object,
  /** Whether street view is available for the address in the ACS event. */
  hasStreetView: PropTypes.bool,
  /** Id to provide to the link to click to show street view. */
  streetViewLinkId: PropTypes.string
};
