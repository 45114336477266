import { createElement } from 'react';
import ManageRolesTab from '../../../components/AdminUsersPage/ManageRolesTab/ManageRolesTab.tsx';
import ManageUsersTab from '../../../components/AdminUsersPage/ManageUsersTab/ManageUsersTab.tsx';
import MyRolesTab from '../../../components/AdminUsersPage/MyRolesTab/MyRolesTab.tsx';
import { createAdminUsersPageModel } from '../../../components/AdminUsersPage/createAdminUsersPageModel.ts';
import { $$resellerManageUsersTab } from './ResellerManageUserTab/model.ts';
import { $$resellerManageUsersRolesModal } from './ResellerManageUserRolesModal/model.ts';
import { $$resellerManageRolesTable } from './ResellerManageRolesTable/model.ts';
import { $$resellerManageUsersAssignedToRoleModal } from './ResellerManageUsersAssignedToRoleModal/model.ts';
import { $$resellerMyRolesTable } from './ResellerMyRolesTable/model.ts';

export const $$resellerAdminUsersPage = createAdminUsersPageModel({
  tabs: [
    {
      name: 'manage-users',
      title: 'Manage Users',
      component: createElement(ManageUsersTab, { $$manageUsersTab: $$resellerManageUsersTab, $$manageUserRolesModal: $$resellerManageUsersRolesModal, isExternal: true })
    },
    { name: 'manage-roles', title: 'Manage Roles', component: createElement(ManageRolesTab, { $$manageRolesTable: $$resellerManageRolesTable, $$manageUsersAssignedToRoleModal: $$resellerManageUsersAssignedToRoleModal}) },
    { name: 'my-roles', title: 'My Roles', component: createElement(MyRolesTab, {$$myRolesTable: $$resellerMyRolesTable}) }
  ],
  openedTabKey: 'admin-user-selected-tab',
  $$manageUserRolesModal: $$resellerManageUsersRolesModal,
  $$manageUserAssignedToRoleModal: $$resellerManageUsersAssignedToRoleModal
});
