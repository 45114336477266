import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactElement } from 'react';

type DefaultCheckboxFieldInputLabelProps = {
  labelTitle: string;
  fieldDescription: ReactElement;
};

export default function DefaultCheckboxFieldInputLabel({ labelTitle, fieldDescription }: DefaultCheckboxFieldInputLabelProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '3px' }}>{labelTitle}</span>
      <Tooltip title={fieldDescription} sx={{ fontSize: 1 }} arrow>
        <InfoOutlinedIcon sx={{ fontSize: 15, verticalAlign: 'super' }} />
      </Tooltip>
    </div>
  );
}
