// @ts-ignore
import { buttonColors, dialogMaxWidths } from '@ghs/components';
import { GridRowId, GridRowModes } from '@mui/x-data-grid-pro';
import { attach, createEffect, createEvent, createStore, sample, split } from 'effector';
import { persist } from 'effector-storage/query';
import { has, keyBy, pick, uniq, isEmpty } from 'lodash';
import { debounce, delay, spread } from 'patronum';
import { createElement } from 'react';
import { $$reseller } from '../../services/ResellerService/model.js';
import { recalculateVersionDates, validateVersionDates } from '../../services/ResellerService/utils.js';
import { treeShakeObject, VOID } from '../../util/JsonUtils.js';
import createDataGridModel from '../../util/createDataGridModel';
import { createDialog } from '../../util/createDialog.js';
import { $$batchEditVersionDatesDialogModel } from '../BatchEditVersionDatesDialog/model.js';
import { $$batchEditVersionNameDialog } from '../BatchEditVersionNameDialog/model.js';
import { $$confirmationDialog } from '../ConfirmationDialog/model.js';
import { $$snackbar } from '../SnackbarRoot/model.js';
import VersionsDeleteConfirmationDialog from './VersionsDeleteConfirmationDialog.jsx';

const dialog = createDialog<{ campaignId: Reseller.Campaign['id'] }>();

const $search = createStore<string>('');

const $isRecalculationEnabled = createStore<boolean>(true);

const $campaign = sample({
  source: {
    campaigns: $$reseller.$campaigns,
    campaignId: dialog.$state.map(state => Number(state?.campaignId) || null)
  },
  fn: ({ campaigns, campaignId }) => campaigns.find(({ id }) => id === campaignId) || null
});

const campaignIdChanged = createEvent<Reseller.Campaign['id'] | null>();

const searchChanged = createEvent<string>();

const realculateToggleChanged = createEvent<boolean>();

const deleteSingleClicked = createEvent<GridRowId>();

const deleteSelectedClicked = createEvent<void>();

const deleteByIdsTriggered = createEvent<Reseller.Version['id'][]>();

const openUnsavedCloseConfirmation = createEvent<{ saveAll: () => void; discardChanges: () => void }>();

const saveAllClicked = createEvent<void>();

const discardClicked = createEvent<void>();

const singleRowSaveClicked = createEvent<GridRowId>();

const editClicked = createEvent<GridRowId>();

const cancelClicked = createEvent<GridRowId>();

const rowsModelChanged = createEvent<import('@mui/x-data-grid').GridRowModesModel>();

const secondConfirmationTriggered = createEvent<GridRowId[]>();

const confirmSaveDialogOpened = createEvent<Reseller.Version[]>();

const validateNameNumberFx = createEffect<Reseller.Version[], DataGridError<Reseller.Version>[]>(async updatedRows => {
  const backendValidationResult = await Promise.all(updatedRows.map(version => pick(version, ['id', 'name', 'number', 'campaignId'])).map($$reseller.validateVersionNameFx));
  const errorMessage = 'The same Version Name / Version Number combination already exists in the campaign and can’t be duplicated.';
  return backendValidationResult
    .map((isValid, index) => (isValid ? null : index))
    .filter(index => index !== null)
    .map(index => [
      { id: updatedRows[index].id, field: 'name' as const, error: errorMessage },
      { id: updatedRows[index].id, field: 'number' as const, error: errorMessage }
    ])
    .flat();
});

const getFreshRowsFx = attach({
  source: {
    campaignId: dialog.$state.map(state => state?.campaignId || null),
    search: $search
  },
  mapParams: (
    pagination: DataGrid.ServerSideParams,
    filters: {
      campaignId: Reseller.Version['campaignId'] | null;
      search: string;
    }
  ) => treeShakeObject({ ...pagination, ...filters }),
  effect: $$reseller.getVersionsFx
});

const $$versions = createDataGridModel({
  type: 'server',
  effect: getFreshRowsFx,
  refetch: [debounce($search.updates, 100), $$reseller.deleteVersionsFx.done]
}) as unknown as DataGridFactory<Reseller.Version>; // After createDataGridModel is typed, this cast can be removed

const getMultipleInHomeWindowsDetailFx = createEffect<
  {
    mailClassIds: Reseller.Version['mailClass']['id'][];
    customerId: Reseller.Campaign['customer']['id'];
    lineOfBusinessId: Reseller.LineOfBusiness['id'];
  },
  unknown
>(async ({ customerId, lineOfBusinessId, mailClassIds }) => {
  return Promise.all(
    mailClassIds.map(mailClassId =>
      $$reseller.getInHomeWindowsDetailFx({
        customerId,
        lineOfBusinessId,
        mailClassId
      })
    )
  );
});

split({
  // @ts-ignore
  source: campaignIdChanged,
  // @ts-ignore
  match: value => (value === null ? 'close' : 'open'),
  cases: {
    // @ts-ignore
    open: dialog.open.prepend(campaignId => ({ campaignId })),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    close: dialog.close.prepend(() => {})
  }
});

// @ts-ignore
persist({
  store: dialog.$state.map(state => state?.campaignId || null),
  key: 'campaign',
  target: campaignIdChanged
});

sample({
  source: searchChanged,
  target: $search
});

sample({
  source: realculateToggleChanged,
  target: $isRecalculationEnabled
});

sample({
  // @ts-ignore
  clock: delay(secondConfirmationTriggered, 0), // Delay is needed to let the confirmation dialog close before opening it again
  fn: ids => ({
    confirmation: {
      title: 'Success!',
      content: 'All your changes have been successfully applied.',
      acceptButtonText: 'Save and exit',
      closeButtonText: 'Undo Changes',
      onAccept: () => {
        // @ts-ignore
        $$reseller.deleteVersionsFx(ids);
      }
    },
    highlight: ids
  }),
  target: spread({
    confirmation: $$confirmationDialog.open,
    highlight: $$versions.highlightRows
  })
});

sample({
  clock: deleteSingleClicked.map(id => [id]),
  fn: ids => ids.map(Number),
  target: deleteByIdsTriggered
});

sample({
  source: $$versions.$selectedRowsIds,
  clock: deleteSelectedClicked,
  fn: ids => ids.map(Number),
  target: deleteByIdsTriggered
});

sample({
  source: $$versions.$data,
  clock: deleteByIdsTriggered,
  fn: (versions, idsToDelete) => ({
    title: 'Version deletion confirmation',
    content: createElement(VersionsDeleteConfirmationDialog, { versions: versions.filter(v => idsToDelete.includes(v.id)) }),
    onAccept: () => secondConfirmationTriggered(idsToDelete),
    acceptButtonText: 'Delete all selected',
    acceptButtonColor: buttonColors.ERROR,
    maxWidth: dialogMaxWidths.XL
  }),
  target: $$confirmationDialog.open
});

sample({
  // @ts-ignore
  clock: openUnsavedCloseConfirmation,
  fn: ({ saveAll, discardChanges }) => ({
    title: 'Wait!',
    content: 'You have unsaved changes. Are you sure you want to leave? If you exit now, all changes will be lost.',
    maxWidth: dialogMaxWidths.SM,
    buttons: [
      {
        children: 'Save all changes',
        onClick: () => {
          saveAll();
          $$confirmationDialog.close();
        },
        variant: 'outlined'
      },
      {
        children: 'Exit anyway',
        onClick: () => {
          discardChanges();
          $$confirmationDialog.close();
          $$campaignVersionsDialog.close();
        },
        variant: 'outlined'
      },
      { children: 'Cancel', onClick: $$confirmationDialog.close, variant: 'contained' }
    ]
  }),
  target: $$confirmationDialog.open
});

sample({
  source: { updatedRows: $$versions.$updatedRows, existingErrors: $$versions.$errors },
  clock: [saveAllClicked.map(() => 'all'), singleRowSaveClicked],
  fn: ({ updatedRows, existingErrors }, id) => {
    const selectedUpdatedRows = updatedRows.filter(v => id === 'all' || v.id === id);
    const dateValidationErrors = selectedUpdatedRows.map(validateVersionDates).flat();

    return {
      updatedRows: selectedUpdatedRows,
      updatedErrors: [...existingErrors, ...dateValidationErrors]
    };
  },
  target: spread({ updatedRows: confirmSaveDialogOpened, updatedErrors: $$versions.setErrors })
});

sample({
  source: $$versions.$errors,
  clock: confirmSaveDialogOpened,
  filter: errors => isEmpty(errors),
  fn: (_errors, updatedRows) => {
    return {
      title: 'Success!',
      content: 'All your changes have been successfully applied.',
      onAccept: () => $$reseller.updateVersionsFx(updatedRows),
      acceptButtonText: 'Save and exit',
      closeButtonText: 'Undo changes'
    };
  },
  target: $$confirmationDialog.open
});

sample({
  clock: validateNameNumberFx.done,
  filter: ({ result }) => result.length > 0,
  fn: ({ result: errors }) => errors,
  target: $$versions.setErrors
});

sample({
  clock: $$reseller.deleteVersionsFx.done,
  fn: ({ params }) => ({
    unselectAllRows: VOID,
    snackbar: {
      message: params.length > 1 ? 'Campaign versions deleted' : 'Campaign version deleted',
      severity: 'success' as const
    }
  }),
  target: spread({ unselectAllRows: $$versions.unselectAllRows, snackbar: $$snackbar.open })
});

sample({
  source: $$versions.$data,
  clock: $$reseller.updateVersionsFx.done,
  fn: (data, { params: updatedRows }) => ({
    unselectAllRows: VOID,
    resetAllChanges: VOID,
    setAllRowsMode: GridRowModes.View,
    data: data.map(version => updatedRows.find(({ id }) => id === version.id) || version)
  }),
  target: spread({
    unselectAllRows: $$versions.unselectAllRows,
    resetAllChanges: $$versions.resetAllChanges,
    setAllRowsMode: $$versions.setAllRowsMode,
    data: $$versions.$data
  })
});

sample({
  source: {
    campaignId: dialog.$state.map(state => state?.campaignId || null),
    unsavedChanges: $$versions.$unsavedChanges
  },
  clock: [
    $$batchEditVersionNameDialog.changesApproved.map(({ campaignId, versionNames: data }) => ({ campaignId, data })),
    $$batchEditVersionDatesDialogModel.changesApproved.map(({ campaignId, versionsDates: data }) => ({
      campaignId,
      data
    }))
  ],
  filter: ({ campaignId }, { campaignId: receivedCampaignId }) => campaignId === receivedCampaignId,
  fn: ({ unsavedChanges }, { data }) => {
    const newChanges = data
      .map(({ id, ...updatedCell }) =>
        Object.keys(updatedCell).map(field => ({
          id,
          field,
          value: updatedCell[field as 'name']
        }))
      )
      .flat() as DataGridChangeUnit<Reseller.Version>[];
    const newUnsavedChanges = [...unsavedChanges.filter(({ id, field }) => !newChanges.some(c => c.id === id && c.field === field)), ...newChanges];
    return {
      unsavedChanges: newUnsavedChanges,
      resetErrors: newUnsavedChanges.map(({ id }) => id)
    };
  },
  target: spread({
    unsavedChanges: $$versions.$unsavedChanges,
    resetErrors: $$versions.resetRowsErrors
  })
});

sample({
  clock: discardClicked,
  target: [$$versions.unselectAllRows, $$versions.resetAllChanges, $$versions.setAllRowsMode.prepend(() => GridRowModes.View)]
});

sample({
  // @ts-ignore
  source: {
    versions: $$versions.$data,
    isRecalculationEnabled: $isRecalculationEnabled,
    unsavedChanges: $$versions.$unsavedChanges,
    inHomeWindowRanges: $$reseller.$inHomeWindowRanges,
    campaign: $campaign
  },
  clock: $$versions.cellValueChanged,
  fn: ({ unsavedChanges, versions, isRecalculationEnabled, inHomeWindowRanges, campaign }, { id, field, value }) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const version = keyBy(versions, 'id')[id] as Reseller.Version | undefined;
    const inHomeWindowRange = inHomeWindowRanges.find(
      range => range.mailClassId === version?.mailClass.id && range.lineOfBusinessId === campaign?.lineOfBusiness.id && range.customerId === campaign?.customer.id
    ) as Reseller.InHomeWindowRange | undefined;
    if (!version || !inHomeWindowRange) {
      return {};
    }
    // @ts-ignore
    const { changes, isRecalculationEnabled: newIsRecalculationEnabled } = recalculateVersionDates({
      field,
      value,
      version,
      isRecalculationEnabled,
      inHomeWindowRange
    });

    const objectToSpread = {
      unsavedChanges: [...unsavedChanges, ...Object.entries(changes).map(([f, v]) => ({ id, field: f, value: v }))],
      isRecalculationEnabled: newIsRecalculationEnabled
    };

    if (has(changes, 'inHomeStartDate')) {
      objectToSpread['resetCellError'] = { id, field: 'inHomeStartDate' };
    } else if (has(changes, 'inHomeEndDate')) {
      objectToSpread['resetCellError'] = { id, field: 'inHomeEndDate' };
    }

    return objectToSpread;
  },
  target: spread({ isRecalculationEnabled: $isRecalculationEnabled, unsavedChanges: $$versions.$unsavedChanges, resetCellError: $$versions.resetCellError })
});

sample({
  clock: $$reseller.updateVersionsFx.done.map(({ params }) => params.map(version => version.id)),
  target: $$versions.highlightRows
});

sample({
  clock: editClicked.map(id => ({ id, mode: GridRowModes.Edit })),
  target: $$versions.setRowMode
});

sample({
  clock: cancelClicked,
  fn: id => ({ setRowMode: { id, mode: GridRowModes.View }, resetRowChanges: id }),
  target: spread({ setRowMode: $$versions.setRowMode, resetRowChanges: $$versions.resetRowChanges })
});

sample({
  clock: dialog.close,
  target: [$isRecalculationEnabled.reinit, $search.reinit]
});

sample({
  clock: $$versions.cellValueChanged,
  filter: ({ field }) => field === 'name' || field === 'number',
  fn: ({ id }) => ({ id, field: 'name' as const }),
  target: $$versions.resetCellError
});

sample({
  source: $$versions.$updatedRows,
  clock: debounce($$versions.cellValueChanged, 1000),
  filter: (_unused, { field }) => field === 'name' || field === 'number',
  fn: (updatedRows, { id }) => updatedRows.filter(v => v.id === id),
  target: validateNameNumberFx
});

sample({
  source: {
    customerId: $campaign.map(campaign => campaign?.customer.id || -1),
    lineOfBusinessId: $campaign.map(campaign => campaign?.lineOfBusiness.id || -1),
    mailClassIds: $$versions.$data.map(data => uniq(data.map(version => version.mailClass.id)))
  },
  clock: [$$versions.tableMounted.filter({ fn: Boolean }), $$versions.$pagination.updates],
  filter: ({ lineOfBusinessId, customerId }) => lineOfBusinessId !== -1 && customerId !== -1,
  target: getMultipleInHomeWindowsDetailFx
});

const $selectedVersionWithScans = sample({
  source: {
    selectedRowIds: $$versions.$selectedRowsIds,
    versions: $$versions.$data
  },
  fn: ({ selectedRowIds, versions }) => versions.some(v => selectedRowIds.includes(v.id) && v.hasScans === true)
});

export const $$campaignVersionsDialog = {
  ...dialog,

  $$versions,

  $campaign,
  $isRecalculationEnabled: $isRecalculationEnabled.map(d => d),
  $search: $search.map(d => d),
  $selectedVersionWithScans: $selectedVersionWithScans.map(d => d),
  $isValidationPending: validateNameNumberFx.pending,
  $isSavePending: $$reseller.updateVersionsFx.pending,

  searchChanged,
  realculateToggleChanged,
  deleteSingleClicked,
  deleteSelectedClicked,
  openUnsavedCloseConfirmation,
  saveAllClicked,
  discardClicked,
  singleRowSaveClicked,
  editClicked,
  cancelClicked,
  rowsModelChanged
};
