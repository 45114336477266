// @ts-ignore
import { Autocomplete, DataGrid, textFieldVariants } from '@ghs/components';
import { Box, FormControl, Grid, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';
import { useUnit } from 'effector-react';
import useMountedEvent from '../../../hooks/useMountedEvent.ts';
import { DelayedTextField } from '../../DelayedTextField';
import { isEmpty } from 'lodash';

/**
 * ManageUsersTable component
 *
 * @returns {React.ReactNode} ManageUsersTable component
 */
export default function ManageUsersTab({ $$manageUsersTab, $$manageUserRolesModal, isExternal }): React.ReactNode {
  useMountedEvent($$manageUsersTab.tableMounted);

  const dataGridProps = $$manageUsersTab.useDataGrid();
  const isResellerUser = useUnit($$manageUsersTab.$isResellerUser);
  const filters = useUnit($$manageUsersTab.$filters);
  const customers = useUnit($$manageUsersTab.$customers).map(customer => ({ id: customer.id, label: customer.name }));

  const usersTableColumns: GridColDef<Users.User, never, never>[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 160,
      flex: 4,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', cursor: 'pointer' }} onClick={() => $$manageUserRolesModal.open({ userId: row.userId })}>
          <Typography sx={{ textDecoration: 'underline' }}>{row.name || ''}</Typography>
        </Box>
      )
    },
    { field: 'email', headerName: 'Email', minWidth: 160, flex: 5 }
  ];

  return (
    <Stack sx={{ flex: 1, gap: 2 }}>
      <FormControl>
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          {isResellerUser && isExternal && (
            <Grid item xs={12} md={3}>
              <Autocomplete
                id="roles-customer-filter"
                label="Customer"
                placeholder="Select"
                options={customers}
                variant={textFieldVariants.OUTLINED}
                sx={{ width: '100%' }}
                name="customer"
                value={filters.customerId ? customers.find(customer => customer.id === Number(filters.customerId)) || null : null}
                onChange={(_event, value) => $$manageUsersTab.setFilterByKey({ key: 'customerId', value: value?.id || null })}
                getOptionKey={option => option.id}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <DelayedTextField
              type="search"
              label="Search User Name or Email"
              placeholder="Search"
              InputLabelProps={{ shrink: true }}
              value={filters.search}
              onChange={value => $$manageUsersTab.setFilterByKey({ key: 'search', value })}
              variant={textFieldVariants.OUTLINED}
              sx={{ maxWidth: '300px', width: '100%' }}
            />
          </Grid>
        </Grid>
      </FormControl>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: isEmpty(dataGridProps.rows) ? 250 : undefined }}>
        <DataGrid
          {...dataGridProps}
          columns={usersTableColumns}
          disableRowSelectionOnClick
          editMode="row"
          onRowDoubleClick={({ id }) => $$manageUserRolesModal.open({ userId: id + '' })}
          initialState={{
            columns: { columnVisibilityModel: { id: false } },
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pagination
          pageSizeOptions={[5, 10, 25, 50]}
          autosizeOnMount={false} // vitest is failing beause of this prop
          autosizeOptions={{
            includeOutliers: true,
            includeHeaders: true
          }}
          sx={DataGridStylesWithoutSelectionOverride}
        />
      </Box>
    </Stack>
  );
}
