import { Container, Typography } from '@mui/material';
import { Accordion, Button } from '@ghs/components';
import { useUnit } from 'effector-react';
import useMountedEvent from '../../hooks/useMountedEvent';
import { hasReleaseNotesPermission } from '../../services/PermissionService/PermissionService';
import { $$gcp } from '../../services/GcpService/model';
import { $$faq } from './model';
import { $$app } from '../../components/App/model';
import ReleaseNotesDialog from './ReleaseNotesDialog';
import SnackbarRoot from '../../components/SnackbarRoot/SnackbarRoot.jsx';

export default function FaqPage() {
  useMountedEvent($$faq.pageMounted);

  const userDetails = useUnit($$app.$userDetails);
  const faqs = useUnit($$gcp.$faqs);

  return (
    <>
      <ReleaseNotesDialog />
      <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />

      <Container id="faq-container">
        {hasReleaseNotesPermission(userDetails?.permissions) && (
          <Button color="primary" onClick={() => $$faq.releaseNotesButtonClicked()} size="medium" variant="contained" sx={{ mb: 2 }}>
            Release Notes
          </Button>
        )}

        <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
          Frequently Asked Questions
        </Typography>

        {faqs.map((faq, index) => {
          return (
            <Accordion key={`faq-${index}`} heading={faq.question}>
              <Container>
                <Typography variant="string">
                  <span>{faq.answer}</span>
                </Typography>
              </Container>
            </Accordion>
          );
        })}
      </Container>
    </>
  );
}
