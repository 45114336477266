import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { gridClasses, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { Box, Button, IconButton, Switch, TextField, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { AdditionalDatesDisplay } from './AdditionalDatesDisplay';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { DataGrid } from '@ghs/components';
import { isEmpty } from 'lodash';

const CustomToolbar = ({ searchQuery, handleSearchChange, showCompletedFiles, getCompletedFilesResults, getRefreshedResults }) => {
  return (
    <GridToolbarContainer /*sx={{backgroundColor: 'primary.main', color: 'primary.contrastText'}}*/>
      <Box sx={{ margin: 0.5 }}>
        <TextField label="Search" variant="outlined" value={searchQuery} onChange={handleSearchChange} autoFocus />
      </Box>
      <Button component="div" startIcon={<Switch checked={showCompletedFiles} />} onClick={getCompletedFilesResults}>
        Show Completed Files
      </Button>
      <Button startIcon={<RefreshIcon />} onClick={getRefreshedResults}>
        Refresh
      </Button>
    </GridToolbarContainer>
  );
};
CustomToolbar.propTypes = {
  searchQuery: PropTypes.string,
  handleSearchChange: PropTypes.func,
  showCompletedFiles: PropTypes.bool,
  getCompletedFilesResults: PropTypes.func,
  getRefreshedResults: PropTypes.func
};

const FileTable = ({ tableLoading, files, handleDialogOpen, stepDetails, showCompletedFiles, getCompletedFilesResults, getRefreshedResults }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };

  const searchTerms = searchQuery
    .toLowerCase()
    .split(' ')
    .filter(term => term);

  const filteredRows = files.filter(row =>
    searchTerms.every(term => ['logId', 'loginId', 'clientHostname', 'jobId', 'uploadedFileName'].some(field => row[field].toString().toLowerCase().includes(term)))
  );

  const customToolbar = () => (
    <CustomToolbar
      searchQuery={searchQuery}
      handleSearchChange={handleSearchChange}
      showCompletedFiles={showCompletedFiles}
      getCompletedFilesResults={getCompletedFilesResults}
      getRefreshedResults={getRefreshedResults}
    />
  );

  const getDetailPanelContent = ({ row }) => {
    let index;

    stepDetails.forEach((dataPoint, dataPointIndex) => {
      if (dataPoint.logId == row.logId) {
        index = dataPointIndex;
      }
    });

    return <AdditionalDatesDisplay stepDetails={stepDetails[index].stepArray} />;
  };

  const getRowId = row => {
    return row.logId;
  };

  const columns = [
    // We render each cell manually so that we can eliminate the hover tooltips and be able to apply testIds for testing.
    {
      field: 'logId',
      minWidth: 120,
      headerName: 'Log ID',
      renderCell: params => <span data-testid="datagrid-cell">{params.value}</span>
    },
    {
      field: 'loginId',
      flex: 1,
      headerName: 'Login ID',
      renderCell: params => <span data-testid="datagrid-cell">{params.value}</span>
    },
    {
      field: 'clientHostname',
      flex: 1,
      headerName: 'Client Hostname',
      renderCell: params => <span data-testid="datagrid-cell">{params.value}</span>
    },
    {
      field: 'jobId',
      minWidth: 120,
      headerName: 'Job ID',
      renderCell: params => <span data-testid="datagrid-cell">{params.value}</span>
    },
    {
      field: 'uploadedFileName',
      flex: 3,
      headerName: 'Uploaded Filename',
      renderCell: params => <span data-testid="datagrid-cell">{params.value}</span>
    },
    {
      field: 'recordCount',
      flex: 1,
      headerName: 'Record Count',
      renderCell: params => <span data-testid="datagrid-cell">{params.value}</span>
    },
    {
      field: 'latestSubStep',
      flex: 1,
      headerName: 'Latest Date',
      width: 300,
      // The date is set to UTC as it will not do any time conversions, and we already have the date from the DB as EST so all we need to do is format.
      renderCell: params => (
        <div data-testid="datagrid-cell" style={{ whiteSpace: 'pre-line', alignItems: 'initial', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption">{params.value.subStepName}</Typography>
          <Typography variant="button">
            {new Date(params.value.value).toString() === 'Invalid Date' ? '' : new Date(params.value.value).toLocaleString('en-US', { timeZone: 'America/New_York' }).replace(', ', ' ')}{' '}
          </Typography>
        </div>
      )
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'KILL',
      headerAlign: 'center',
      getActions: row => [
        <IconButton
          key={`row-${row.id}`}
          disabled={row.row.isCompleted}
          onClick={() => {
            handleDialogOpen(row.row.logId, row.row.uploadedFileName);
          }}
        >
          <DangerousIcon sx={{ color: row.row.isCompleted ? 'grey.main' : 'error.main' }} />
        </IconButton>
      ]
    }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: isEmpty(filteredRows) ? 250 : undefined }}>
      <DataGrid
        initialState={{
          ...filteredRows.initialState,
          pagination: { paginationModel: { pageSize: 5 } }
        }}
        slots={{
          toolbar: customToolbar
        }}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none'
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none'
          },
          '& .MuiDataGrid-cell': {
            alignContent: 'center'
          }
        }}
        loading={tableLoading}
        columns={columns}
        rows={filteredRows}
        pageSizeOptions={[5, 10, 25]}
        getDetailPanelContent={getDetailPanelContent}
        getRowId={getRowId}
        getDetailPanelHeight={() => 'auto'}
        getRowHeight={() => 'auto'}
        pagination
        disableMultipleRowSelection
        disableRowSelectionOnClick
      ></DataGrid>
    </Box>
  );
};
FileTable.propTypes = {
  tableLoading: PropTypes.bool,
  handleDialogOpen: PropTypes.func,
  showCompletedFiles: PropTypes.bool,
  getCompletedFilesResults: PropTypes.func,
  getRefreshedResults: PropTypes.func,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      cassNcoaHost: PropTypes.string,
      clientHostname: PropTypes.string,
      isCompleted: PropTypes.bool,
      jobId: PropTypes.number,
      latestSubStep: PropTypes.object,
      logId: PropTypes.number,
      loginId: PropTypes.string,
      recordCount: PropTypes.number,
      steps: PropTypes.array,
      uploadedFileName: PropTypes.string
    })
  ),
  stepDetails: PropTypes.arrayOf(
    PropTypes.shape({
      logId: PropTypes.number,
      stepArray: PropTypes.array
    })
  )
};

export default FileTable;
