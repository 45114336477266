import dayjs from 'dayjs';
import { z } from 'zod';

export const splTabSlugs = {
  byPieceNumber: 'by-piece-number',
  byName: 'by-name'
} as const;

export type SplTabSlug = (typeof splTabSlugs)[keyof typeof splTabSlugs];

export type FormErrors<FormObject extends Record<string, unknown> = Record<string, unknown>> = Partial<Record<keyof FormObject, string>>;

export type SearchResultItem = {
  id: number;
  name: string;
  zip: string;
  userField1: string;
  userField2: string;
  userField3: string;
  mailDate: string;
  product: string;
  cycleJobNum: string;
  clientSeqNum: string;
  address: string;
  city: string;
  state: string;
  trackCode: string;
  pkgId: number;
  divName: string;
  businessName: string;
  lettershopCode: string;
  lettershop: string;
  branch: string;
  hasAcs: boolean;
};

const DateRangeZod = z
  .object({
    startDate: z.string().refine(value => dayjs(value, 'MM/DD/YYYY').isValid(), { message: 'Start Date is not a valid date' }),
    endDate: z.string().refine(value => dayjs(value, 'MM/DD/YYYY').isValid(), { message: 'End Date is not a valid date' })
  })
  .partial();

const SearchFilterZod = z.object({
  customerId: z.number().nullable().optional(),
  lineOfBusinessId: z.number().nullable().optional(),
  campaignId: z.number().nullable().optional()
});

export const SplFormByPieceNumberZod = z.union([
  z
    .object({
      pieceNumberType: z.literal('imb'),
      imb: z.string().min(1, 'IMB is required').default('')
    })
    .merge(DateRangeZod)
    .merge(SearchFilterZod),
  z
    .object({
      pieceNumberType: z.literal('uniqueRecordId'),
      seqNum: z.string().min(1, 'Unique Record ID is required').default('')
    })
    .merge(DateRangeZod)
    .merge(SearchFilterZod)
]);

export type SplFormByPieceNumber = z.infer<typeof SplFormByPieceNumberZod>;

export const getSplFormByNameZod = (isSt: boolean) =>
  z
    .object({
      name: z.string().optional(),
      businessName: z.string().optional(),
      city: z.string().optional(),
      zip: z.string().optional(),
      userField1: z.string().optional(),
      userField2: z.string().optional(),
      userField3: z.string().optional()
    })
    .merge(DateRangeZod)
    .merge(SearchFilterZod)
    .superRefine((data, ctx) => {
      const requiredFields = ['name', 'businessName', ...(isSt ? ['userField1', 'userField2', 'userField3'] : [])];
      if (requiredFields.every(field => !data[field])) {
        const errorMsg = isSt ? 'Full Name, Business Name, or at least one User Field is required' : 'Full Name or Business Name is required';

        requiredFields.forEach(field => {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: errorMsg, path: [field] });
        });
      }
    });

export const SplFormByNameZod = getSplFormByNameZod(false); // Default instance for type inference

export type SplFormByName = z.infer<typeof SplFormByNameZod>;

export const SplFormDataZod = z.union([SplFormByPieceNumberZod, SplFormByNameZod]);

export type SplFormData = z.infer<typeof SplFormDataZod>;
