import { createEvent, createStore, createEffect, sample, attach } from 'effector';
import { and, delay, spread } from 'patronum';
import { $$bi, UserDetails } from '../../services/BiService';
import { $$contactService } from '../../services/ContactService/model';
import { fetchPermissionsFx, hasDashboardPermission } from '../../services/PermissionService/PermissionService';
import { $$personaService } from '../../services/PersonaService/model';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { $$blockingDialog } from '../BlockingDialog/model';
import { Box, CircularProgress, Typography } from '@mui/material';
import { VOID } from '../../util/JsonUtils';
import { ReactElement } from 'react';
import { $$auth } from '../../services/AuthService/model';

const RELOAD_USER_DETAILS_MAX_RETRIES = 3;

//-------------------------------- Events --------------------------------
const reinitAll = createEvent<void>();
const pageMounted = createEvent<boolean>();
const personaChanged = createEvent<{ newPersona: Persona; getAccessTokenSilently: () => Promise<string>; navigate: NavigateFunction }>();

//-------------------------------- Stores --------------------------------
const $userDetails = createStore<UserDetails>(null).reset(reinitAll);
const $loadComplete = createStore(false).reset(reinitAll);

//-------------------------------- Effects -------------------------------

const setActivePersonaFx = attach({ effect: $$personaService.setActivePersonaFx });

const $reloadUserDetailsRetryCount = createStore(1).reset(setActivePersonaFx.done, reinitAll);
const reloadUserDetailsDelay = delay({
  source: $reloadUserDetailsRetryCount,
  timeout: reloadUserDetailsRetryCount => {
    if (reloadUserDetailsRetryCount === 2) {
      return 2000;
    } else if (reloadUserDetailsRetryCount === 3) {
      return 5000;
    } else {
      return 10000;
    }
  }
});

const switchActivePersonaFx = createEffect(async ({ getAccessTokenSilently, navigate }) => {
  // 1) Before this function, 'setActivePersonaFx' was called to set the new persona in Auth0

  // 2) Get a new access token since the persona has changed
  await getAccessTokenSilently({ cacheMode: 'off' });

  // 3) Load the data for the new persona
  await loadUserDetailsFx();

  // 4) Force reloading the page
  navigate('/');
});

const loadUserDetailsFx = createEffect(async () => {
  try {
    // Terminate Sisense session (if any). This is not needed because a new session will be opened when triggering
    // $$bi.reloadSisenseInfo -> getSisenseInfoFx -> getSisenseInfo -> performBiServerLogin
    await $$bi.performBiServerLogoutFx();
  } catch (error) {
    // Don't prevent the user details loading from completing if Sisense logout fails
    console.log('Error logging out of BI server: ', error);
  }

  try {
    const [userPermissions, userPersonas, userActivePersona] = await Promise.allSettled([
      fetchPermissionsFx(),
      $$personaService.getAvailablePersonasFx(),
      $$personaService.getActivePersonaFx(),
      $$contactService.getContactInfoFx()
    ]);

    if (userPermissions.status !== 'fulfilled') {
      throw new Error('Failed to fetch user permissions');
    }
    if (userPersonas.status !== 'fulfilled') {
      throw new Error('Failed to fetch user personas');
    }
    if (userActivePersona.status !== 'fulfilled') {
      throw new Error('Failed to fetch active persona');
    }

    const userDetails = {
      permissions: userPermissions.value,
      personas: userPersonas.value,
      activePersona: userActivePersona.value,
      isMt: userActivePersona.value.ghsSystem === 'MAP',
      isSt: userActivePersona.value.ghsSystem === 'ST'
    };

    if (hasDashboardPermission(userPermissions.value)) {
      // Load Sisense status (Needed to check whether the dashboards menu item and the home dashboard or a banner must be rendered)
      $$bi.reloadSisenseInfo();
    }

    return userDetails;
  } catch (error) {
    throw new Error(error.message || 'Unknown error while loading user details');
  }
});

//-------------------------------- Samples -------------------------------
sample({
  clock: [pageMounted.filter({ fn: Boolean }).map(() => ({})), reloadUserDetailsDelay],
  fn: () => ({ isSwitchPersona: false }),
  target: loadUserDetailsFx
});

sample({
  clock: loadUserDetailsFx.doneData,
  fn: userDetails => ({
    userDetails,
    // This is to make sure all the permissions and dashboards info is available when executing the router.
    // If we don't do this, this will run asynchronously, hence these state variables would be "undefined" when,
    // for example, using them to decide whether the menu items should be displayed.
    // It's also helps to avoid suddenly adding the menu items that depend on specific conditions to be
    // displayed. For example, the dashboards and the admin menu icons.
    loadComplete: true,
    closeDialog: VOID
  }),
  target: spread({
    userDetails: $userDetails,
    loadComplete: $loadComplete,
    closeDialog: $$blockingDialog.close
  })
});

sample({
  source: $reloadUserDetailsRetryCount,
  clock: loadUserDetailsFx.fail,
  fn: (reloadUserDetailsRetryCount, error) => {
    console.error('Failed to load user details:', error); // Delete or keep it for troubleshooting

    const dialogContent = {
      title: 'Initialization Error',
      content:
        reloadUserDetailsRetryCount <= RELOAD_USER_DETAILS_MAX_RETRIES ? (
          <>
            <Typography>Oops, it looks like we hit a slight snag in the personalization process!</Typography>
            <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
              <img src={'robot.png'} alt="Robot" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
            <Typography mt={1}>Our team of highly trained robots is working diligently to resolve the issue and get you back on track.</Typography>
            <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
              <CircularProgress />
            </Box>
            <Typography mt={1}>Attempt {reloadUserDetailsRetryCount}. Trying to reconnect...</Typography>
          </>
        ) : (
          <>
            <Typography>Despite their best efforts, we&apos;re unable to complete the personalization process at this time.</Typography>
            <Typography mt={1}>Please contact your support team for further assistance.</Typography>
            <Typography mt={1}>We know this is frustrating and we apologize for the inconvenience.</Typography>
          </>
        )
    };

    const result: {
      loadComplete: boolean;
      dialogContent: {
        title: string;
        content: ReactElement;
      };
      reloadUserDetailsRetryCount?: number;
    } = {
      loadComplete: true,
      dialogContent: dialogContent
    };

    // If retry count is within limits, increase the retry counter so the delayed effect is called
    if (reloadUserDetailsRetryCount <= RELOAD_USER_DETAILS_MAX_RETRIES) {
      result.reloadUserDetailsRetryCount = reloadUserDetailsRetryCount + 1;
    }

    return result;
  },
  target: spread({
    loadComplete: $loadComplete,
    dialogContent: $$blockingDialog.open,
    reloadUserDetailsRetryCount: $reloadUserDetailsRetryCount
  })
});

sample({
  clock: personaChanged,
  fn: ({ newPersona }) => {
    return {
      newPersona,
      dialogContent: {
        title: 'Changing Persona',
        content: (
          <>
            <Typography>Please wait while your persona is loaded...</Typography>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
              <CircularProgress />
            </Box>
          </>
        )
      }
    };
  },
  target: spread({
    newPersona: setActivePersonaFx,
    dialogContent: $$blockingDialog.open
  })
});

sample({
  clock: setActivePersonaFx.doneData,
  source: personaChanged, // Needed so 'getAccessTokenSilently' and 'navigate' are passed to 'switchActivePersonaFx'
  target: switchActivePersonaFx
});

sample({
  clock: setActivePersonaFx.fail,
  fn: () => ({
    title: 'Error changing persona',
    content: 'An error occurred while changing your persona. Please reload the application and try again.'
  }),
  target: $$blockingDialog.open
});

sample({
  clock: switchActivePersonaFx.done,
  target: $$blockingDialog.close
});

sample({
  clock: switchActivePersonaFx.fail,
  fn: () => ({
    title: 'Error switching persona',
    content: 'There was an error switching the active persona.'
  }),
  target: $$blockingDialog.open
});

export const $$app = {
  $userDetails,
  $loadComplete: and($loadComplete, $$auth.$isAuthenticated),

  loadUserDetailsFx,

  pageMounted,
  personaChanged,

  __: {
    reinitAll
  }
};
