import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, Checkbox, Dialog, ListItemText, MenuItem, Select, TextField } from '@ghs/components';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Collapse, Container, FormControl, FormControlLabel, FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import PropTypes from 'prop-types';
import React from 'react';
import { getAllPagesFlattened } from './Layout/Pages';
import { saveBanner } from '../services/BannerService';
import { $$contactService } from '../services/ContactService/model';
import { generateErrorAlertWithContact } from '../util/ErrorUtils';

const severity = [
  { value: 'success', name: 'Success' },
  { value: 'info', name: 'Info' },
  { value: 'warning', name: 'Low Severity Alert' },
  { value: 'error', name: 'High Severity Alert' }
];

/**
 * Component for displaying the upload logo form in a dialog.
 *
 * @param {object} props props for this component
 * @param {boolean} props.open whether the dialog should be open
 * @param {Function} props.onClose handler for when the dialog closes
 * @param {object} props.banner banner to be edited
 * @param {string} props.title Title for dialog
 * @returns {React.ReactNode} BannerDialog component
 * @class
 */
const BannerDialog = ({ open, onClose, banner, title }) => {
  const contactInfo = useUnit($$contactService.$contactInfo);

  const handleDialogClose = (_e, reason, reloadData, savedBanner) => {
    if (reason && reason == 'backdropClick') return;
    setSaveDisabled(true);
    onClose(null, reloadData, savedBanner);
  };

  const handleSave = async event => {
    event.preventDefault();
    if (isFormValid()) {
      try {
        setLoading(true);
        const savedBanner = await saveBanner(formData);

        setSelectedPages([]);
        setLoading(false);
        handleDialogClose(null, 'save', true, savedBanner);
      } catch (_) {
        setAlertData(
          generateErrorAlertWithContact({
            contactInfo: contactInfo,
            userEmail: user?.email,
            errorMessage: 'There was an error saving banner. Please retry'
          })
        );
        setLoading(false);
      }
    }
  };

  const handleClear = async () => {
    setSelectedPages([]);
    handleDialogClose(null, 'clear', false, banner);
  };

  const { user } = useAuth0();
  const [appPages, setAppPages] = React.useState([]);

  const defaultFormData = {
    id: null,
    name: '',
    content: '',
    severity: '',
    pages: [],
    active: true,
    errors: {}
  };

  const [formData, setFormData] = React.useState(defaultFormData);
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [selectedPages, setSelectedPages] = React.useState([]);
  const [alertData, setAlertData] = React.useState(null);
  const [checked, setChecked] = React.useState(true);

  React.useEffect(() => {
    if (banner) {
      let { pages, ...loadedBanner } = banner;
      loadedBanner.pages = Array.isArray(pages) ? pages : pages?.split(',').map(item => item.trim());
      setSelectedPages(loadedBanner.pages);
      setFormData({ ...loadedBanner, ...{ errors: {} } });
      setChecked(loadedBanner.active);
    } else {
      setFormData(defaultFormData);
      setChecked(defaultFormData.active);
      setSelectedPages([]);
    }

    const filteredAppPages = getAllPagesFlattened()
      .filter(page => page.showBanners)
      .map(page => page.fullName);
    setAppPages(filteredAppPages);
  }, [banner, open]);

  React.useEffect(() => {
    setSaveDisabled(formData.name?.length <= 0 && formData.content?.length <= 0);
  }, [formData]);

  const handleFormChange = event => {
    const newData = { [event.target.name]: event.target.value };
    setFormData({
      ...formData,
      ...newData
    });
  };

  const isFormValid = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    if (!formData.content) {
      errors.content = 'Content is required';
    }
    if (!formData.pages) {
      errors.pages = 'Page is required';
    }
    if (formData.active == undefined) {
      errors.active = 'Status is required';
    }
    if (!formData.severity) {
      errors.severity = 'Severity is required';
    }
    setFormData({ ...formData, ...{ errors: errors } });
    return Object.keys(errors).length === 0;
  };

  const handlePagesChange = event => {
    const {
      target: { value }
    } = event;
    setSelectedPages(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value.map(item => item.trim())
    );
    setFormData(prevFormData => ({
      ...prevFormData,
      pages: value
    }));
    handleFormChange(event);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleDialogClose}
      title={title}
      actions={
        <Box sx={{ display: 'flex' }}>
          <Button form="banner-form" color="secondary" onClick={handleClear} size="medium" variant="contained" disabled={null} sx={{ mt: 1, ml: 1 }}>
            Cancel
          </Button>

          <Button color="primary" onClick={handleSave} size="medium" variant="contained" disabled={loading || saveDisabled} sx={{ mt: 1, ml: 1 }}>
            Save
          </Button>
        </Box>
      }
    >
      <Container component="form" id="banner-form">
        <Collapse id="alert-collapse" in={!!alertData && (alertData.title || alertData.message)}>
          <Alert
            severity={alertData?.severity}
            action={
              <IconButton onClick={() => setAlertData({ ...alertData, ...{ title: '', message: '' } })}>
                <CloseIcon />
              </IconButton>
            }
          >
            <Typography variant="h6">{alertData?.title}</Typography>
            <Typography>{alertData?.message}</Typography>
          </Alert>
        </Collapse>
        <Box
          sx={{
            ...(loading && {
              position: 'absolute',
              zIndex: 1,
              verticalAlign: 'middle',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0.7,
              display: 'flex',
              width: 'fill-available',
              height: 'fill-available'
            })
          }}
        >
          <CircularProgress sx={{ ...(!loading && { display: 'none' }) }} size={60} />
        </Box>
        <Stack>
          <FormControl>
            <TextField name="id" label="id" value={formData.id} sx={{ display: 'none' }} />
            <TextField name="name" label="Name" value={formData.name} error={!!formData.errors.name} onChange={handleFormChange} size="small" sx={{ mt: 1, mb: 1, maxWidth: '400px' }} />
            <FormHelperText error={!!formData.errors.name} style={{ color: 'red' }}>
              {formData.errors.name ? formData.errors.name : ''}
            </FormHelperText>
            <TextField name="content" label="Content" multiline value={formData.content} error={!!formData.errors.content} onChange={handleFormChange} size="small" sx={{ mb: 1, maxWidth: '400px' }} />
            <FormHelperText error={!!formData.errors.content} style={{ color: 'red' }}>
              {formData.errors.content ? formData.errors.content : ''}
            </FormHelperText>
            <Select
              id="pages-select"
              name="pages"
              label="Pages"
              multiple
              error={!!formData.errors.pages}
              value={selectedPages}
              onChange={handlePagesChange}
              size="small"
              sx={{ mb: 1, maxWidth: '400px' }}
              renderValue={selected => selected.join(', ')}
            >
              {appPages.map((page, index) => (
                <MenuItem key={`option-${index}`} value={page}>
                  <Checkbox checked={selectedPages?.findIndex(item => item.trim() === page.trim()) >= 0} />
                  <ListItemText primary={page} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!formData.errors.pages} style={{ color: 'red' }}>
              {formData.errors.pages ? formData.errors.pages : ''}
            </FormHelperText>
            <Select
              id="severity-select"
              name="severity"
              label="Severity"
              error={!!formData.errors.severity}
              helperText={formData.errors.severity}
              value={formData.severity}
              onChange={handleFormChange}
              size="small"
              sx={{ mb: 1, maxWidth: '400px' }}
            >
              {severity.map((item, index) => {
                return (
                  <MenuItem data-testid="severity-option" key={`severity-option-${index}`} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    checked={checked}
                    onChange={event => {
                      setChecked(event.target.checked);
                      event.target.value = event.target.checked;
                      handleFormChange(event);
                    }}
                  />
                }
                label="Active"
              />
              <FormHelperText error style={{ color: 'red' }}>
                {formData.errors.active ? formData.errors.active : ' '}
              </FormHelperText>
            </FormControl>
          </FormControl>
        </Stack>
      </Container>
    </Dialog>
  );
};

BannerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  permissions: PropTypes.array,
  banner: PropTypes.object,
  title: PropTypes.string
};

export default BannerDialog;
