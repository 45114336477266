import { DataGrid } from '@ghs/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';

/**
 * MyRolesTable component
 *
 * @type {React.FC<{}>}
 * @returns {React.ReactNode} MyRolesTable component
 */
export default function MyRolesTable({ $$myRolesTable }): React.ReactNode {
  const dataGridProps = $$myRolesTable.$$rolesDataGrid.useDataGrid();

  const customerTableColumns: GridColDef<Roles.Role, never, never>[] = [
    { field: 'name', headerName: 'Assignment', minWidth: 160, flex: 4 },
    { field: 'description', headerName: 'Description', minWidth: 160, flex: 6 }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: isEmpty(dataGridProps.rows) ? 250 : undefined }}>
      <DataGrid
        {...dataGridProps}
        columns={customerTableColumns}
        disableRowSelectionOnClick
        editMode="row"
        getRowId={row => row.id}
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pagination={false}
        hideFooter={true}
        autosizeOnMount={false} // vitest is failing beause of this prop
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: true
        }}
        sx={DataGridStylesWithoutSelectionOverride}
      />
    </Box>
  );
}
