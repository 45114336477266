import { createManageUsersAssignedToRoleModal } from '../../../../components/AdminUsersPage/ManageUsersAssignedToRoleModal/createManageUsersAssignedToRoleModal.ts';
import { $$roles } from '../../../../services/RolesService/model.ts';
import { $$user } from '../../../../services/UserService/UserService.ts';

export const $$ghsAdminManageUsersAssignedToRoleModal = createManageUsersAssignedToRoleModal({
  getUsersForRole: $$roles.adminGetUsersForRole,
  deleteUsersFromRoleFx: $$roles.adminDeleteUsersFromRoleFx,
  assignUserToRoleFx: $$roles.adminAssignUserToRoleFx,
  getUsersFx: $$user.adminGetUsersFx,
  $roles: $$roles.$adminRoles,
});