import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { $$app } from './model';
import Layout from '../Layout/Layout';
import ProtectedRoute from './ProtectedRoute';
import { AddressVerification } from '../../pages/AddressVerification';
import BiDashboard from '../../pages/DashboardsPage/BiDashboard';
import DashboardsPage from '../../pages/DashboardsPage/DashboardsPage';
import Callback from '../../pages/Callback';
import Contact from '../../pages/Contact';
import FaqPage from '../../pages/FaqPage/FaqPage';
import { FileProcessing } from '../../pages/FileProcessing';
import Home from '../../pages/Home';
import { MailTrakClientLOB } from '../../pages/MailTrakClientLOB';
import { MailTrakAutoRating } from '../../pages/MailTrakAutoRating';
import NoPage from '../../pages/NoPage';
import { pages } from '../Layout/Pages';
import SinglePieceLookupPage from '../../pages/SinglePieceLookupPage/SinglePieceLookupPage';
import SplResultsPage from '../../pages/SplResultsPage/SplResultsPage';
import StartLogin from '../../pages/StartLogin';
import StartLogout from '../../pages/StartLogout';
import AdminCampaignsPage from '../../pages/admin/AdminCampaignsPage/AdminCampaignsPage';
import { AdminCustomers } from '../../pages/admin/AdminCustomersPage';
import AdminDataAccessPage from '../../pages/admin/AdminDataAccessPage/AdminDataAccessPage';
import { AdminInHomeWindows } from '../../pages/admin/AdminInHomeWindows';
import { AdminLogos } from '../../pages/admin/AdminLogos';
import ResellerAdminUsersPage from '../../pages/admin/ResellerAdminUsersPage/ResellerAdminUsersPage.tsx';
import GhsAdminBanners from '../../pages/ghsadmin/GhsAdminBanners';
import GhsAdminDashboards from '../../pages/ghsadmin/GhsAdminDashboards/GhsAdminDashboards';
import GhsAdminLogos from '../../pages/ghsadmin/GhsAdminLogos';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  hasAddressVerificationPermission,
  hasAdminEditCampaignsVersionsPermissions,
  hasAdminEditCustomersLobsPermissions,
  hasAdminInHomeWindowsPermissions,
  hasAdminLogosPermissions,
  hasAdminManageUsersDataAccessPermissions,
  hasAdminRolesPermissionsPermissions,
  hasDashboardPermission,
  hasFileProcessingPermission,
  hasGhsAdminBannerPermissions,
  hasGhsAdminDashboardThumbnailsPermissions,
  hasGhsAdminLogoPermissions, hasGhsAdminRolesPermissionsPermissions,
  hasMailTrakAutoRatingPermission,
  hasMailTrakClientLobPermission,
  hasSinglePieceLookupPermission
} from '../../services/PermissionService/PermissionService';
import GhsAdminUsersPage from '../../pages/ghsadmin/GhsAdminUsersPage/GhsAdminUsersPage.tsx';

/**
 * Router component that will set the document title based on the pathname.
 *
 * @returns {React.ReactNode} Router
 */
export default function Router() {
  const userDetails = useUnit($$app.$userDetails);

  const location = useLocation();
  useEffect(() => {
    const currentPage = pages.find(page => page.path === location.pathname);
    // Don't update the title for the dashboard page because it'll be replaced by the dashboard title
    if (currentPage) {
      if (currentPage.name !== 'Dashboard') {
        document.title = currentPage.name;
      }
    } else {
      document.title = 'GrayHair Client Portal';
    }
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<StartLogin />} />
        <Route path="/" element={<Layout pages={pages} />}>
          {/* The home page will be cached in the Layout to avoid mounting & unmounting the Sisense components
                     again and again. We need to keep the index route with ProtectedRoute rendering an empty container
                      for the Auth0 redirection to work */}
          <Route index element={<ProtectedRoute component={Home} />} />
          <Route path="dashboards" element={<ProtectedRoute component={DashboardsPage} checkAccessFn={() => hasDashboardPermission(userDetails?.permissions)} />} />
          <Route path="lookup" element={<ProtectedRoute component={SinglePieceLookupPage} checkAccessFn={() => hasSinglePieceLookupPermission(userDetails?.permissions)} />} />
          <Route path="splResults" element={<ProtectedRoute component={SplResultsPage} checkAccessFn={() => hasSinglePieceLookupPermission(userDetails?.permissions)} />} />
          <Route path="contact" element={<ProtectedRoute component={Contact} />} />
          <Route path="faq" element={<ProtectedRoute component={FaqPage} />} />
          <Route path="logout" element={<StartLogout />} />
          <Route path="ghsAdmin/logos" element={<ProtectedRoute component={GhsAdminLogos} checkAccessFn={() => hasGhsAdminLogoPermissions(userDetails?.permissions)} />} />
          <Route path="ghsAdmin/dashboards" element={<ProtectedRoute component={GhsAdminDashboards} checkAccessFn={() => hasGhsAdminDashboardThumbnailsPermissions(userDetails?.permissions)} />} />
          <Route path="ghsAdmin/banners" element={<ProtectedRoute component={GhsAdminBanners} checkAccessFn={() => hasGhsAdminBannerPermissions(userDetails?.permissions)} />} />
          <Route path="ghsAdmin/roles" element={<ProtectedRoute component={GhsAdminUsersPage} checkAccessFn={() => hasGhsAdminRolesPermissionsPermissions(userDetails?.permissions)} />} />
          <Route path="ghsAdmin/fileProcessing" element={<ProtectedRoute component={FileProcessing} checkAccessFn={() => hasFileProcessingPermission(userDetails?.permissions)} />} />
          <Route path="ghsAdmin/mailTrakClientLOB" element={<ProtectedRoute component={MailTrakClientLOB} checkAccessFn={() => hasMailTrakClientLobPermission(userDetails?.permissions)} />} />
          <Route path="ghsAdmin/mailTrakAutoRating" element={<ProtectedRoute component={MailTrakAutoRating} checkAccessFn={() => hasMailTrakAutoRatingPermission(userDetails?.permissions)} />} />
          <Route path="admin/roles" element={<ProtectedRoute component={ResellerAdminUsersPage} checkAccessFn={() => hasAdminRolesPermissionsPermissions(userDetails?.permissions)} />} />
          <Route path="admin/campaigns" element={<ProtectedRoute component={AdminCampaignsPage} checkAccessFn={() => hasAdminEditCampaignsVersionsPermissions(userDetails?.permissions)} />} />
          <Route path="admin/inHomeWindows" element={<ProtectedRoute component={AdminInHomeWindows} checkAccessFn={() => hasAdminInHomeWindowsPermissions(userDetails?.permissions)} />} />
          <Route path="admin/data-access" element={<ProtectedRoute component={AdminDataAccessPage} checkAccessFn={() => hasAdminManageUsersDataAccessPermissions(userDetails?.permissions)} />} />
          <Route path="admin/customers" element={<ProtectedRoute component={AdminCustomers} checkAccessFn={() => hasAdminEditCustomersLobsPermissions(userDetails?.permissions)} />} />
          <Route path="admin/logos" element={<ProtectedRoute component={AdminLogos} checkAccessFn={() => hasAdminLogosPermissions(userDetails?.permissions)} />} />
          <Route path="callback" element={<Callback />} />
          <Route path="dashboard" element={<ProtectedRoute component={BiDashboard} checkAccessFn={() => hasDashboardPermission(userDetails?.permissions)} unmountShouldUnloadEmbedSdk={false} />} />
          <Route path="addressVerification" element={<ProtectedRoute component={AddressVerification} checkAccessFn={() => hasAddressVerificationPermission(userDetails?.permissions)} />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </>
  );
}

Router.propTypes = {};
