import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton, Container } from '@mui/material';
import { useUnit } from 'effector-react';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllPagesFlattened } from './Layout/Pages';
import { $$bannerService, Banner } from '../services/BannerService';
import { $$personaService } from '../services/PersonaService/model';
import { hashCode } from '../util/JsonUtils';

function BannerContainer() {
  const activePersona = useUnit($$personaService.$activePersona);
  const [bannersForCurrentPage, setBannersForCurrentPage] = useState<Banner[]>([]);
  const [openAlerts, setOpenAlerts] = useState<{ id: Banner['id']; open: boolean }[]>([]);
  const location = useLocation();

  const loadBanners = async () => {
    const currentPagePath = location.pathname.indexOf('callback') > -1 ? '/' : location.pathname;
    const pageName = getAllPagesFlattened().find(page => page.path === currentPagePath)?.fullName;
    const banners = pageName ? await $$bannerService.getBannersForPageFx(pageName) : [];
    const cookies = decodeURIComponent(document.cookie).split(';');
    //remove banners already shown in this session
    const notSeenBanners = banners.filter(banner => !cookies.some(cookie => cookie.includes(getBannerHash(banner) + '=true')));

    const newOpenAlerts = notSeenBanners.map(banner => ({ id: banner.id, open: true }));
    setOpenAlerts(newOpenAlerts);
    setBannersForCurrentPage(banners);
  };

  const getBannerHash = (banner: Banner) => activePersona?.id + '-' + banner.id + '-' + banner.updatedAt;

  const closeBanner = (closedBanner: Banner) => {
    const openArray = openAlerts.map(banner => (banner.id == closedBanner.id ? { ...banner, open: !banner.open } : banner));
    setOpenAlerts(openArray);
    setCookie(getBannerHash(closedBanner), 'true');
  };

  /**
   * Saves cookie with an expiration date.
   *
   * @param {string} cName name
   * @param {string} cValue value
   */
  function setCookie(cName: string, cValue: string) {
    document.cookie = cName + '=' + cValue + ';samesite=strict';
  }

  useEffect(() => {
    setBannersForCurrentPage([]);
    loadBanners();
  }, [location.pathname, activePersona]);

  return (
    <Container id="banner-container">
      {bannersForCurrentPage.map(banner => (
        <Collapse key={banner.id} in={openAlerts.filter(o => o.id === banner.id)[0]?.open}>
          <Alert
            id={banner.id}
            key={banner.id}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  closeBanner(banner);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            severity={banner.severity ? banner.severity : 'error'}
          >
            <AlertTitle>{banner.name}</AlertTitle>
            {banner.content.split('\n').map(line => (
              <Fragment key={`${banner.id}-line-${hashCode(line)}`}>
                {line}
                <br />
              </Fragment>
            ))}
          </Alert>
        </Collapse>
      ))}
    </Container>
  );
}

/**
 * Component that hides children after a delay.
 */
type ExpireProps = {
  delay: number;
  children: React.ReactNode;
};

function Expire(props: ExpireProps) {
  const { delay, children: propsChildren } = props;

  const [isVisible, setIsVisible] = useState(true);
  const [children, setChildren] = useState(propsChildren);

  useEffect(() => {
    setChildren(propsChildren);
    setIsVisible(true);
    setTimer(delay);
  }, [propsChildren]);

  const setTimer = (timerDelay: number) => {
    setTimeout(() => setIsVisible(false), timerDelay);
  };

  if (!isVisible) {
    return null;
  }

  return <div>{children}</div>;
}

Expire.propTypes = {
  delay: PropTypes.string.isRequired,
  children: PropTypes.array
};

export default BannerContainer;
